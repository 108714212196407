<template>
  <v-card class="elevation-0 mb-10 card-comment">
    <div class="d-flex">
      <v-avatar
        class="mr-6 white--text font-weight-bold text-h5"
        size="60"
        :color="generateColor(comment.initials)"
      >
        {{ comment.initials }}
      </v-avatar>

      <div>
        <div class="body-2">
          <span class="font-weight-bold">{{ comment.user }}</span>, {{ comment.date
          }}
        </div>
        <div
          class="body-2 mt-4"
          v-html="comment.message"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import colorAvatarMixin from '@/mixins/colorAvatar'
export default {
  props: {
    comment: {},
  },
  mixins: [
    colorAvatarMixin,
  ],
}
</script>

<style>
.card-comment p{
  margin-bottom: 8px;
}
.card-comment img {
  width: 100%;
}
</style>

import Api from "@/apis/Api.js";
import Csrf from "@/apis/Csrf"

export default {

  // Get Dpc
  async getDpc(dpcId) {
    await Csrf.getCookie();
    return Api.get('/forum/' + dpcId + '/dpc');
  },

  // Get Dpc
  async getTopics(dpcId) {
    await Csrf.getCookie();
    return Api.get('/forum/' + dpcId + '/index');
  },

  //  Subscribe to topic
  async subscribeTopic(dpcId, topicId) {
    await Csrf.getCookie();
    return Api.post('/forum/' + dpcId + '/subscribe', { 'id': topicId });
  },

  //  Unsubscribe from topic
  async unsubscribeTopic(dpcId, topicId) {
    await Csrf.getCookie();
    return Api.post('/forum/' + dpcId + '/unsubscribe', { 'id': topicId });
  },

  //  Save Subject
  async saveSubject(dpcId, sujet, message) {
    await Csrf.getCookie();
    return Api.post('/forum/' + dpcId + '/sujet', { 'sujet': sujet, 'message': message });
  },

}
<template>
  <div>
    <v-radio-group 
      v-model="value" 
      :row="part.horizontal"
      :rules="radioRules"
      :disabled="disabled"
    >
      <v-radio
        v-for="(option,j) in part.options"
        :key="j"
        :label="option.label"
        :value="j + 1"
      />
      <v-radio
        v-if="part.open"
        :key="part.options.length"
        :label="part.openLabel"
        value="autre"
      />
    </v-radio-group>
    <Animated 
      enter="fadeIn" 
      leave="fadeOut"
    >
      <v-text-field
        v-if="part.open && value == 'autre'"
        :label="part.openTextLabel"
        v-model="autre"
        :rules="openRules"
      />
    </Animated>
  </div>
</template>

<script>
export default {
    props: {
        part: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        value: null,
        autre: null,
    }),
    computed:{
        radioRules : function(){
            if (this.part.required && !this.disabled) {
                return [v => !!v || 'Ce champ est obligatoire.']
            } else {
                return [];
            }
        },
        openRules : function(){
            if (this.part.required && !this.disabled && this.value == 'autre') {
                return [v => !!v || 'Ce champ est obligatoire.']
            } else {
                return [];
            }
        },
        answers(){
            if(this.part.open){
                return (this.value == 'autre')?[this.value,this.autre]:[this.value,null];
            } else {
                return this.value;
            }
        },
    },
    watch:{
        value(){
            if (this.value != 'autre') this.autre = null;
        },
        answers() {
            this.$emit('input', this.answers);
        },
        disabled(){
            if (this.disabled) {
                this.value = null;
                this.autre = null;
            }
        } 
    },
}
</script>
import Dpc from './DpcModule.vue'

export const DpcRoutes = [

    {
        path: '/dpc/:id',
        name: 'Dpc',
        component: Dpc,
        meta: { 
            title: 'DPC',
            requiresAuth: true 
        }
    },
        
]
import ForumList from './list/ForumList.vue'
import ForumTopic from './view/ForumTopic.vue'

export const ForumRoutes = [
    // List
    {
        path: '/forum/:dpcId',
        name: 'Forum',
        component: ForumList,
        meta: {
            title: 'Forum de discussion',
            requiresAuth: true,
        }
    },
    // Topic
    {
        path: '/forum/:dpcId/sujet/:topicId',
        name: 'ForumTopic',
        component: ForumTopic,
        meta: {
            title: 'Sujet de discussion',
            requiresAuth: true
        }
    },
]
import Dashboard from './DashboardModule.vue'

export const DashboardRoutes = [

    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta: { 
            title: 'DPC ARMP - Tableau de bord',
            requiresAuth: true,
        }
    },
    
]
<template>
  <div class="parcours-outer">
    <v-card 
      outlined 
      class="pa-md-4 pa-lg-5"
    >
      <v-card-title class="d-block primary white--text rounded">
        <h3>Parcours DPC</h3>
      </v-card-title>
      
      <v-stepper 
        v-model="stepper" 
        alt-labels 
        class="elevation-0 ma-5 pa-0"
      >
        <v-stepper-header>
          <template v-for="(step,index) in steps">
            <v-stepper-step
              :key="`${index + 1}-dpc-step`"
              :complete="stepper > index + 1"
              :step="index + 1"
              color="warning"
            >
              {{ step }}
            </v-stepper-step>
            <v-divider
              v-if="index + 1 < steps.length"
              :key="index + 1"
            />
          </template>
        </v-stepper-header>    
      </v-stepper>
            
      <Animated 
        enter="slideInRight" 
        leave="slideOutLeft"
      >
        <v-card-text 
          v-if="parts.length > 0" 
          style="font-size:inherit;" 
          class="pt-5"
        >
          <Animated 
            enter="fadeIn" 
            leave="fadeOut" 
            :duration="{ enter: 1000, leave: 0 }"
          >
            <div>
              <v-sheet 
                v-if="soumis && afficherReponses" 
                class="grey darken-2 white--text d-inline-block px-5 py-2 mb-2 mr-2 rounded"
              >
                <h3>Les réponses</h3>
              </v-sheet>
            </div>
          </Animated>

          <Animated 
            enter="fadeIn" 
            leave="fadeOut" 
            :duration="{ enter: 1000, leave: 0 }"
          >
            <div v-if="soumis && !afficherReponses">
              <v-sheet class="grey darken-2 white--text d-inline-block px-5 py-2 mb-2 mr-2 rounded">
                <h3>L'essentiel pour votre pratique</h3>
              </v-sheet>
            </div>
          </Animated>

          <Animated 
            enter="fadeIn" 
            leave="fadeOut" 
            :duration="{ enter: 1000, leave: 0 }"
          >
            <v-sheet class="warning white--text d-inline-block px-5 py-2 mb-2 mr-2 rounded">
              <h3>{{ titre }}</h3>
            </v-sheet>
          </Animated>

          <Animated 
            enter="fadeIn" 
            leave="fadeOut" 
            :duration="{ enter: 1000, leave: 0 }"
          >
            <div v-if="soumis && afficherReponses">
              <h3 class="my-10">
                Vous avez eu {{ score }} bonne(s) réponse(s) sur {{ maxScore }} question(s).
              </h3>

              <v-alert
                class="grey lighten-2 mt-6"
                dense
                variant="grey"
              >
                Vos réponses ne sont pas affichées automatiquement. Vous pouvez les revoir en cliquant sur <em>Afficher ma réponse</em>.<br>
                Si <em>Afficher ma réponse</em> est en vert, votre réponse est correcte, si c'est en rouge, votre réponse est incorrecte.
              </v-alert>

              <template v-for="(part,index) in parts">
                <div 
                  class="my-10" 
                  v-if="part.type == 'html'" 
                  :key="'part-' + index" 
                  v-html="parseHtml(part.data)"
                />

                <div 
                  v-if="part.type == 'radio'" 
                  :key="'part-' + index"
                >
                  <h3 class="my-10">
                    {{ part.question }}
                  </h3>

                  <span 
                    v-for="(option,i) in part.options" 
                    :key="i" 
                    :class="part.horizontal ? 'mr-5' : 'd-block'"
                  >
                    <span :class="(i+1) == part.valid ? 'green--text' : ''">
                      <v-icon 
                        class="mr-1" 
                        :color="(i+1) == part.valid ? 'green':'black'" 
                        v-if="(i+1) == part.valid"
                      >mdi-radiobox-marked</v-icon>
                      <v-icon 
                        class="mr-1" 
                        v-else
                      >mdi-radiobox-blank</v-icon>
                      {{ option.label }}
                    </span>
                  </span>

                  <div class="mt-5">
                    <v-btn 
                      x-small
                      outlined
                      @click="toggleAfficherMesReponses(index)"
                      :color="(answers[index] == part.valid)?'green':'red'"
                    >
                      <v-icon 
                        color="green" 
                        x-small
                        class="mr-2"
                        v-if="answers[index] == part.valid"
                      >
                        mdi-check-bold
                      </v-icon>

                      <v-icon 
                        color="red" 
                        x-small
                        class="mr-2"
                        v-if="answers[index] != part.valid"
                      >
                        mdi-alert-circle-outline
                      </v-icon>

                      <span v-if="afficherMesReponses[index] == false">Afficher ma réponse</span>
                      <span v-else>Cacher ma réponse</span>
                    </v-btn>
                  </div>

                  <div 
                    v-if="afficherMesReponses[index] == true" 
                    class="mt-5"
                  >
                    <span 
                      v-for="(option,i) in part.options" 
                      :key="i" 
                      :class="part.horizontal ? 'mr-5' : 'd-block'"
                    >
                      <span :class="(i+1) == answers[index] ? ((answers[index] == part.valid)?'green--text':'red--text') : ''">
                        <v-icon 
                          class="mr-1" 
                          :color="(answers[index] == part.valid)?'green':'red'" 
                          v-if="(i+1) == answers[index]"
                        >mdi-radiobox-marked</v-icon>
                        <v-icon 
                          class="mr-1" 
                          v-else
                        >mdi-radiobox-blank</v-icon>
                        {{ option.label }}
                      </span>
                    </span>
                  </div>
                </div>

                <div 
                  v-if="part.type == 'grilleradios'" 
                  :key="'part-' + index"
                >
                  <h3 class="my-10">
                    {{ part.question }}
                  </h3>                  

                  <v-row 
                    no-gutters
                    style="border-bottom:1px solid #000000;"
                  >
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                    />

                    <v-col
                      v-for="(option,i) in part.options"
                      :key="i"
                      class="pa-3 text-center white--text black"
                    >
                      {{ option.label }}
                    </v-col>
                  </v-row>

                  <v-row 
                    no-gutters 
                    v-for="(question,i) in part.questions" 
                    :key="i"
                    style="border-left:1px solid #000000;"
                  >
                    <v-col
                      cols="12"
                      md="6"
                      lg="4"
                      class="pa-3 black--text"
                      style="border-bottom:1px solid #000000;border-right:solid 1px #000000;"
                    >
                      <div>{{ question.label }}</div>

                      <v-btn 
                        x-small
                        outlined
                        @click="toggleAfficherMesReponsesGrilleRadio(index,i)"
                        class="mt-2"
                        :color="(answers[index][i] == question.valid)?'green':'red'"
                      >
                        <v-icon 
                          color="green" 
                          x-small
                          class="mr-2"
                          v-if="answers[index][i] == question.valid"
                        >
                          mdi-check-bold
                        </v-icon>

                        <v-icon 
                          color="red" 
                          x-small
                          class="mr-2"
                          v-if="answers[index][i] != question.valid"
                        >
                          mdi-alert-circle-outline
                        </v-icon>

                        <span v-if="afficherMesReponses[index][i] == false">Afficher ma réponse</span>
                        <span v-else>Cacher ma réponse</span>
                      </v-btn>
                    </v-col>

                    <v-col
                      v-for="(option,j) in part.options"
                      :key="j"
                      class="text-center pa-3"
                      style="border-bottom:1px solid #000000;border-right:solid 1px #000000;"
                    >
                      <v-icon 
                        color="green" 
                        class="mx-1"
                        v-if="(j+1) == question.valid"
                      >
                        mdi-radiobox-marked
                      </v-icon>

                      <v-icon 
                        color="green" 
                        class="mx-1"
                        v-if="afficherMesReponses[index][i] && (j+1) == question.valid && (j+1) == answers[index][i]"
                      >
                        mdi-radiobox-marked
                      </v-icon>

                      <v-icon 
                        color="red" 
                        class="mx-1"
                        v-if="afficherMesReponses[index][i] && (j+1) != question.valid && (j+1) == answers[index][i]"
                      >
                        mdi-radiobox-marked
                      </v-icon>
                    </v-col>
                  </v-row>
                </div>

                <div 
                  v-if="part.type == 'checkboxgroup'" 
                  :key="'part-' + index"
                >
                  <h3 class="my-10">
                    {{ part.question }}
                  </h3>

                  <span 
                    v-for="(option,i) in part.options" 
                    :key="i" 
                    class="d-block"
                  >
                    <span 
                      :class="part.valid.find(element => element == (i+1)) ? 'green--text' : ''"
                    >
                      <v-icon 
                        class="mr-1" 
                        :color="part.valid.find(element => element == (i+1)) ? 'green':'black'" 
                        v-if="part.valid.find(element => element == (i+1))"
                      >mdi-checkbox-marked</v-icon>
                      <v-icon 
                        class="mr-1" 
                        v-else
                      >mdi-checkbox-blank-outline</v-icon>
                      {{ option.label }}
                    </span>
                  </span>

                  <div class="mt-5">
                    <v-btn 
                      x-small
                      outlined
                      @click="toggleAfficherMesReponses(index)"
                      :color="compareArray(answers[index],part.valid)?'green':'red'"
                    >
                      <v-icon 
                        color="green" 
                        x-small
                        class="mr-2"
                        v-if="compareArray(answers[index],part.valid)"
                      >
                        mdi-check-bold
                      </v-icon>

                      <v-icon 
                        color="red" 
                        x-small
                        class="mr-2"
                        v-if="!compareArray(answers[index],part.valid)"
                      >
                        mdi-alert-circle-outline
                      </v-icon>

                      <span v-if="afficherMesReponses[index] == false">Afficher ma réponse</span>
                      <span v-else>Cacher ma réponse</span>
                    </v-btn>
                  </div>

                  <div 
                    v-if="afficherMesReponses[index] == true" 
                    class="mt-5"
                  >
                    <span 
                      v-for="(option,i) in part.options" 
                      :key="i" 
                      class="d-block"
                    >
                      <span :class="answers[index].find(element => element == (i+1)) ? part.valid.find(element => element == (i+1))?'green--text':'red--text' : part.valid.find(element => element == (i+1))?'red--text':''">
                        <v-icon 
                          class="mr-1" 
                          :color="part.valid.find(element => element == (i+1))?'green':'red'" 
                          v-if="answers[index].find(element => element == (i+1))"
                        >mdi-checkbox-marked</v-icon>
                        <v-icon 
                          class="mr-1" 
                          :color="part.valid.find(element => element == (i+1))?'red':''" 
                          v-else
                        >mdi-checkbox-blank-outline</v-icon>
                        {{ option.label }}
                      </span>
                    </span>
                  </div>
                </div>

                <v-divider 
                  v-if="part.type == 'divider'" 
                  :key="'divider-' + index" 
                  class="mt-10 mb-5"
                />
              </template>
            </div>
          </Animated>

          <Animated 
            enter="fadeIn" 
            leave="fadeOut" 
            :duration="{ enter: 1000, leave: 0 }"
          >
            <div  
              v-if="soumis && !afficherReponses"
              ref="enSavoirPlus" 
              class="my-10" 
              v-html="parseHtml(enSavoirPlus)" 
            />
          </Animated>
          
          <Animated 
            enter="fadeIn" 
            leave="fadeOut" 
            :duration="{ enter: 1000, leave: 0 }"
          >
            <v-form ref="form">
              <div v-if="!soumis">
                <template v-for="(part,index) in parts">
                  <div 
                    class="my-5" 
                    v-if="part.type == 'html'" 
                    :key="'part-' + index" 
                    v-html="parseHtml(part.data)"
                  />                

                  <div 
                    v-if="part.type == 'radio'" 
                    :key="'part-' + index"
                  >
                    <h3 class="my-10">
                      {{ part.question }}
                    </h3>
                    <inputRadios 
                      v-model="form.answers[index]" 
                      :part="part"
                      :disabled="(part.prerequisite)?form.answers[part.prerequisite_index] != part.prerequisite_value:false" 
                    />
                  </div>

                  <div 
                    v-if="part.type == 'grilleradios'" 
                    :key="'part-' + index"
                  >
                    <h3 class="my-10 black--text">
                      {{ part.question }}
                    </h3>
                    <input-grille-radios 
                      v-model="form.answers[index]" 
                      :part="part" 
                      :disabled="(part.prerequisite)?form.answers[part.prerequisite_index] != part.prerequisite_value:false" 
                    />
                  </div>

                  <div 
                    v-if="part.type == 'checkboxgroup'" 
                    :key="'part-' + index"
                  >
                    <h3 class="my-10">
                      {{ part.question }}
                    </h3>
                    <inputCheckboxes 
                      v-model="form.answers[index]" 
                      :part="part"
                      :disabled="(part.prerequisite)?form.answers[part.prerequisite_index] != part.prerequisite_value:false" 
                    />
                  </div>
                  <v-divider 
                    v-if="part.type == 'divider'" 
                    :key="'divider-' + index" 
                    class="mt-10 mb-5" 
                  />
                </template>

                <v-card
                  flat
                  color="blue-grey lighten-5"
                  light
                  class="mt-12 mb-5"
                  v-if="typeEtape == 'action'"
                >
                  <v-card-title
                    class="blue-grey darken-1 white--text"
                  >
                    <v-spacer />
                    <h4>Evaluez vos réponses :</h4>
                    <v-spacer />
                  </v-card-title>
                  <v-card-text 
                    class="text-center py-10 px-xs-0 px-sm-10"
                  >
                    <v-slider
                      v-model="form.evaluation"
                      min="0"
                      max="100"
                      step="10"
                      :thumb-color="evaluationMoved ? 'blue darken-1' : 'blue-grey darken-1'"
                      thumb-size="40"
                      thumb-label="always"
                      class="pt-10"
                      color="blue darken-1"
                      @change="evaluationMoved = true"
                      @click="evaluationMoved = true"
                    >
                      <template v-slot:prepend>
                        <v-chip class="blue darken-1 white--text hidden-sm-and-down">
                          <h3 class="text-no-wrap">
                            Pas du tout sûr
                          </h3>
                        </v-chip>
                        <v-icon 
                          color="blue darken-1" 
                          class="hidden-md-and-up"
                        >
                          mdi-emoticon-sad-outline
                        </v-icon>
                      </template>
                      <template v-slot:thumb-label="{value}">
                        <strong>{{ value }}%</strong>
                      </template>                    
                      <template v-slot:append>
                        <v-chip class="blue darken-1 white--text hidden-sm-and-down">
                          <h3 class="text-no-wrap">
                            Tout à fait sûr
                          </h3>
                        </v-chip>
                        <v-icon 
                          color="blue darken-1" 
                          class="hidden-md-and-up"
                        >
                          mdi-emoticon-happy-outline
                        </v-icon>
                      </template>    
                    </v-slider>
                  </v-card-text>
                </v-card>
              </div>
            </v-form>
          </Animated>
        </v-card-text>
      </Animated>

      <Animated 
        enter="fadeIn" 
        leave="fadeOut"
      >
        <v-alert
          border="top"
          color="orange lighten-1"
          dark
          v-if="parts.length > 0 && soumis && !afficherReponses && typeEtape == 'action' && ((enSavoirPlusPdf && !pdfClicked) || timer > 0)"
          class="my-10 text-center"
          icon="mdi-alert-circle-outline"
        >
          <span v-if="!pdfClicked">Veuillez télécharger l'essentiel de cette section (PDF). </span>
          <span v-if="timer > 0">Vous avez 5 minutes incompressibles avant de passer à l'étape suivante.</span>
        </v-alert>
      </Animated>

      <Animated 
        enter="slideInRight" 
        leave="slideOutLeft"
      >
        <v-card-actions 
          v-if="parts.length > 0" 
          class="justify-center my-4"
        >
          <v-btn 
            v-if="typeEtape == 'info'" 
            large 
            class="my-4 mx-2 px-5" 
            color="success"
            @click="submit"
            depressed
          >
            Continuer
            <v-icon class="ml-2">
              mdi-chevron-right
            </v-icon>
          </v-btn>

          <v-btn 
            v-if="!soumis && typeEtape == 'action'" 
            large 
            class="my-4 mx-2 px-5" 
            @click="submit" 
            color="success"
            depressed
          >
            Enregistrer &amp; continuer 
            <v-icon class="ml-2">
              mdi-content-save-move-outline
            </v-icon>
          </v-btn>

          <v-btn 
            v-if="soumis && !afficherReponses && typeEtape == 'action' && enSavoirPlusPdf" 
            large 
            class="my-4 mx-2 px-5" 
            color="info"
            depressed
            @click="openEnSavoirPlusPdf"
          >
            <v-icon class="mr-1">
              mdi-file-pdf-outline
            </v-icon>
            Télécharger l’essentiel 
          </v-btn>          

          <v-btn 
            v-if="soumis && !afficherReponses && typeEtape == 'action'" 
            large 
            class="my-4 mx-2 px-5" 
            @click="goToReponses" 
            color="success"
            depressed
            :disabled="(!pdfClicked && !!enSavoirPlusPdf) || timer > 0"
          >
            {{ timer > 0 ? '(' + countDownText + ')' : '' }}
            Continuer
            <v-icon 
              class="ml-2"
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>

          <v-btn 
            v-if="soumis && afficherReponses && typeEtape == 'action'" 
            large 
            class="my-4 mx-2 px-5" 
            @click="goToEssentiel" 
            color="info"
            depressed
          >
            <v-icon class="mr-2">
              mdi-information-outline
            </v-icon> 
            Revoir l’essentiel 
          </v-btn>

          <v-btn 
            v-if="soumis && afficherReponses && typeEtape == 'action'" 
            large 
            class="my-4 mx-2 px-5" 
            @click="submit" 
            color="success"
            depressed
          >
            Continuer 
            <v-icon class="ml-2">
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </Animated>
      <v-card-text 
        v-if="parts.length == 0" 
        class="text-center"
      >
        <v-icon 
          color="primary" 
          large
        >
          mdi-loading mdi-spin
        </v-icon>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import inputRadios        from '../inputs/InputRadios'
import inputCheckboxes    from '../inputs/InputCheckboxes'
import inputGrilleRadios  from '../inputs/InputGrilleRadios'
import parseHtmlMixin     from '@/mixins/parseHtml'

export default {
  name: 'Parcours',
  props: {
    steps:{
      type: Array,
      required : true,
    },
  },
  data: () => ({
    evaluationMoved: false,
    timer: 0,
    pdfClicked : false,
    form: {
      answers: null,
      evaluation: 50
    },
    afficherReponses: false,
    afficherMesReponses: [],
  }),
  computed:{
    countDownText(){
      if (this.timer > 0){
        let time = this.timer;
        let minutes = Math.floor(time / 60);
        let seconds = time - (minutes * 60);
        return (minutes > 0 ? minutes + 'm' : '') + (seconds > 0 ? seconds + 's' : '');
      } else {
        return '';
      }
    },
    titre(){
      return this.$store.getters['course/getTitre'];
    },
    stepper(){
      return this.$store.getters['course/getStepper'];
    },
    soumis(){
      return this.$store.getters['course/isSubmitted'];
    },
    parts(){
      return this.$store.getters['course/getParts'];
    },
    answers(){
      return this.$store.getters['course/getAnswers'];
    },
    evaluation(){
      return this.$store.getters['course/getEvaluation'];
    },
    maxScore(){
      return this.$store.getters['course/getMaxScore'];
    },
    score(){
      return this.$store.getters['course/getScore'];
    },
    enSavoirPlus(){
      return this.$store.getters['course/getEnSavoirPlus'];
    },
    enSavoirPlusPdf(){
      if (this.$store.getters['course/getEnSavoirPlusPdf']){
        return process.env.VUE_APP_URL + '/dpc/download/' + this.$store.getters['course/getEnSavoirPlusPdfId'] + '/' + this.$store.getters['course/getEnSavoirPlusPdf'];
      } else {
        return null;
      }
    },
    enSavoirPlusTimer(){
      return this.$store.getters['course/getEnSavoirPlusTimer'];
    },
    typeEtape(){
      let typeEtape = 'info';
      this.parts.forEach(function(part){
        if (part.type == 'radio' || part.type == 'grilleradios' || part.type == 'checkboxgroup') typeEtape = 'action';
      })
      return typeEtape;
    }
  },
  methods: {
    init(){
      this.form.evaluation = 50;
      this.evaluationMoved = false;
      this.afficherReponses = false;
      this.pdfClicked = false;
      if (this.typeEtape == 'action'){
        this.prepareAnswers(this.parts);
        if (this.$refs.form){
          this.$refs.form.reset();
        }
      }
      this.timer = this.enSavoirPlusTimer; // 5 minutes
    },
    openEnSavoirPlusPdf(){
      window.open(this.enSavoirPlusPdf);
      this.pdfClicked = true;
    },
    timerTick(){
      if (this.timer > 0){
        this.timer -= 1
      }
      setTimeout(() => {
        this.timerTick()
      }, 1000)
    },
    goToReponses(){
      this.$store.dispatch('scrollToTop');
      this.afficherReponses = true;
    },
    goToEssentiel(){
      this.$store.dispatch('scrollToTop');
      this.afficherReponses = false;
    },
    submit(){
      if (this.typeEtape == 'info'){
        this.$store.dispatch('course/gotoNext');
      } else {
        if (!this.soumis){
          if (this.$refs.form.validate()) {
            if (this.evaluationMoved){
              this.$store.dispatch('course/saveForm',this.form);
            } else {
              this.$store.dispatch('overlay/setMessages',[{type:'info',message:'N\'oubliez pas d\'évaluer vos réponses en déplaçant le curseur'}]);
            }
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'info',message:'Merci de répondre à toutes les questions obligatoires'}]);
          }
        } else {
          this.$store.dispatch('course/gotoNext');
        }
      }
    },
    prepareAnswers(parts){
      let answers = [];
      let value = null;
      parts.forEach (part => {
        switch(part.type) {
          case 'checkboxgroup':
            part.multiple == true ? value = [] : value = null;
            break;
          case 'grilleradios':
            value = [];
            part.questions.forEach(()=>{
              value.push(null);
            });
            break;
          default:
            value = null;
        }         
        answers.push(value);
      });
      this.form.answers = answers;
    },
    toggleAfficherMesReponses(index){
      this.$set(this.afficherMesReponses, index, !this.afficherMesReponses[index])
    },
    toggleAfficherMesReponsesGrilleRadio(i,j){
      this.$set(this.afficherMesReponses[i], j, !this.afficherMesReponses[i][j])
    },
    compareArray(array1,array2){
      return JSON.stringify(array1.slice().sort()) == JSON.stringify(array2.slice().sort());
    },
  },
  beforeMount(){
    this.$store.dispatch('course/init');
  },
  mounted(){
    this.$store.dispatch('course/getCurrent');
    this.init();
    this.timerTick();
  },
  watch:{
    parts(){
      this.init();
    },
    answers(){
        this.afficherMesReponses = [];
        this.parts.forEach((part)=>{
          if (part.type == 'grilleradios'){
            let tmp = [];
            part.questions.forEach(()=>{
              tmp.push(false);
            });
            this.afficherMesReponses.push(tmp);
          } else {
            this.afficherMesReponses.push(false);
          }
        });
    },
  },
  components:{
    inputCheckboxes,
    inputRadios,
    inputGrilleRadios,
  },
  mixins: [
    parseHtmlMixin,
  ],
}
</script>

<style>
.v-messages{
  min-height: unset;
}
.grille-col .v-input--radio-group{
  padding:0;
  margin: 0;
}
.grille-col .v-input__slot{
  padding:0;
  margin: 0;
}
.theme--light.v-card > .v-card__text {
  color: #000000;
}
.theme--light.v-label{
  color:#000000;
}
.theme--light.v-icon{
  color:#000000;
}

.parcours-outer .theme--light.v-stepper .v-stepper__header .v-stepper__step{
  flex-basis:unset;
  padding: 0;
}
.parcours-outer .theme--light.v-stepper .v-stepper__header .v-stepper__step .v-stepper__label {
  font-size: 12px;
}
.parcours-outer .theme--light.v-stepper .v-stepper__header .v-divider{
  border: none;
}
img.content-images{
  width:auto;
  height:auto;
  max-width:100%;
}  
@media screen and (min-width: 961px) {
  img.content-images{
    max-width:700px;
  }  
}

/* Fit div in window */
.fit-window{
  max-height: 80vh;
  max-width: 142.224vh;
}
</style>
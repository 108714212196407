import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "./store"

import { AuthRoutes }         from './components/auth/routes'
import { InscriptionRoutes }  from './components/inscription/routes'
import { DashboardRoutes }    from './components/dashboard/routes'
import { DpcRoutes }          from './components/dpc/routes'
import { ForumRoutes }        from './components/forum/routes'

Vue.use(VueRouter)

const routes = [
  { 
    path: "*", 
    component: () => import("./components/pageNotFound/PageNotFound.vue"),
    meta: {
      auth: false,
      title: 'Page introuvable'
    }
  },

  // Module Routes
  ...AuthRoutes,
  ...DashboardRoutes,
  ...InscriptionRoutes,
  ...DpcRoutes,
  ...ForumRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to,from,next) => {

  if (to.meta.requiresAuth){
    if (store.getters['auth/isLoggedIn']){
      next();
    } else {
      next({
        name: 'Connexion'
      });
    }
  } else if (to.meta.requiresGuest){
    if (store.getters['auth/isLoggedIn']){
      next({
        name: 'Dashboard'
      });
    } else {
      next();
    }
  } else {
    next();
  }

})

export default router

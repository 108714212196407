<template>
  <v-app>
    <appbar-component />

    <toolbar-component />

    <v-main>
      <v-sheet 
        class="px-4" 
        style="width:100%;" 
        tile 
        v-if="isLoggedIn && showMenu" 
        color="d-block gray lighten-2 pa-2 text-right"
      >
        Bienvenue {{ user.name }} !
      </v-sheet>
      <router-view :key="$route.fullPath" />
    </v-main>

    <Animated 
      enter="slideInUp" 
      leave="slideOutDown"
    >
      <footer-component v-if="showMenu" />
    </Animated>

    <overlayComponent />
  </v-app>
</template>

<script>
import overlayComponent from '@/components/overlay/Overlay.vue'
import appbarComponent  from '@/components/appbar/Appbar.vue'
import footerComponent  from '@/components/footer/Footer.vue'
import toolbarComponent from '@/components/toolbar/Toolbar.vue'

export default {
  components: {
    overlayComponent,
    appbarComponent,
    footerComponent,
    toolbarComponent
  },
  computed: {
    showMenu(){
      return this.$store.getters['appbar/isMenuOn'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    user() {
      return this.$store.getters['auth/getUser'];
    }
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'DPC ARMP - Tableau de bord'
    }
  },
  mounted(){
    document.title = 'DPC ARMP - Tableau de bord'
  },
}
</script>

<style>
.v-application{
  color: #000000;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}
.v-btn {
  text-transform: none;
}
</style>

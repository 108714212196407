<template>
  <Animated
    enter="fadeIn"
    leave="fadeOut"
  >
    <v-toolbar
      v-if="showToolbar"
      dark
      style="position:fixed; width:100vw; z-index: 1;"
      flat
      color="primary"
    >
      <v-tooltip 
        right 
        color="primary darken-2"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="Retour"
            icon
            v-bind="attrs"
            v-on="on"
            class="mr-4"
          >
            <v-icon>mdi-arrow-left-bold-circle-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ backRouteTooltip }}</span>
      </v-tooltip>

      <v-toolbar-title>
        <h3 class="hidden-md-and-up">
          DPC
        </h3>
        <h3 class="hidden-sm-and-down">
          {{ titre }}
        </h3>
      </v-toolbar-title>
      <!-- <v-spacer /> -->
    </v-toolbar>
  </Animated>
</template>

<script>
export default {
  computed: {
    titre() {
      return this.$store.getters['toolbar/getTitre'];
    },
    backRouteTooltip() {
      return this.$store.getters['toolbar/getBackRouteTooltip'];
    },
    showToolbar() {
      return this.$store.getters['toolbar/showToolbar'];
    },
  },
  methods: {
    Retour() {
      this.$router.push({ name: this.$store.getters['toolbar/getBackRouteName'], params: this.$store.getters['toolbar/getBackRouteParams'] });
    },
  }
}
</script> 
<template>
  <v-card
    class="mb-5" 
    outlined
  >
    <v-toolbar
      dense
      flat
      dark
      color="blue-grey darken-3"
    >
      <v-toolbar-title>Mes programmes DPC terminés</v-toolbar-title>

      <v-spacer />

      <v-btn icon>
        <v-icon v-if="refreshing">
          mdi-loading mdi-spin
        </v-icon>
        <v-icon
          v-else
          @click="refresh"
        >
          mdi-refresh
        </v-icon>
      </v-btn>

      <v-btn
        icon
        v-if="display"
      >
        <v-icon @click="display = !display">
          mdi-chevron-up
        </v-icon>
      </v-btn>

      <v-btn
        icon
        v-else
      >
        <v-icon @click="display = !display">
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </v-toolbar>

    <v-expand-transition>
      <v-container
        fluid
        v-if="display"
        class="pa-5"
      >
        <v-row v-if="liste.length > 0">
          <v-col
            v-for="session in liste"
            :key="session.id"
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="3"
          >
            <v-card
              outlined
              class="d-flex flex-column fill-height"
            >
              <v-img
                height="200"
                :src="session.dpc.image"
              >
                <template v-slot:placeholder>
                  <v-layout
                    fill-height
                    align-center
                    justify-center
                    ma-0
                  >
                    <v-progress-circular
                      indeterminate
                      color="warning"
                    />
                  </v-layout>
                </template>

                <v-container>
                  <h3 class="p-0 m-0">
                    {{ session.dpc.titre }}
                  </h3>
                  <div class="my-2">
                    <v-chip
                      v-for="theme in session.dpc.themes"
                      :key="theme.id"
                      class="mr-1 mt-1"
                      x-small
                    >
                      {{ theme.titre }}
                    </v-chip>
                  </div>
                  <div class="mt-2 caption">
                    <div v-if="session.dpc.dpcref != ''">
                      Référence ANDPC : {{ session.dpc.dpcref }}
                    </div>
                    <!-- <div v-if="session.dpc.dpcsession != ''">
                      Session : {{ session.dpc.dpcsession }}
                    </div> -->
                  </div>
                </v-container>
              </v-img>

              <v-card-text
                v-html="parseHtml(session.dpc.intro)"
                class="pb-0"
              />

              <!-- <v-card-actions> -->
              <div class="ma-2">
                <!-- <v-tooltip 
                  v-if="session.dpc.forum == true"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2 mb-2 px-3"
                      color="info"
                      @click="gotoForum(session.dpc.id)"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                    >
                      <template>
                        Forum de discussion
                      </template>
                      <v-icon
                        right
                        dark
                      >
                        mdi-forum-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Accéder au forum de discussion</span>
                </v-tooltip> -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-2 mb-2 px-3"
                      color="success"
                      @click="continueSession(session.id)"
                      v-bind="attrs"
                      v-on="on"
                      depressed
                    >
                      <template>
                        Résultats
                      </template>
                      <v-icon
                        right
                        dark
                      >
                        mdi-arrow-right-circle-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter les résultats de ce programme DPC</span>
                </v-tooltip>
              </div>
              <!-- </v-card-actions> -->
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <div class="body-2 text-center mx-auto pa-4">
            Vous n'avez complété aucun programme DPC
          </div>
        </v-row>
      </v-container>
    </v-expand-transition>
  </v-card>
</template>

<script>
import api from './apis/Api'
import parseHtmlMixin from '@/mixins/parseHtml'

export default {
  data: () => ({
    liste: [],
    refreshing: false,
    display: true,
  }),
  methods: {
    getListe() {

      this.refreshing = true;

      api.getListe()
        .then(response => {

          if (response.data.data) {
            this.liste = response.data.data;
          }

          this.refreshing = false;

        })
        .catch(error => {

          this.refreshing = false;

          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              // this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }

        });
    },
    continueSession(id) {
      this.$router.push({ name: 'Dpc', params: { id: id } })
    },
    gotoForum(id) {
      this.$router.push({ name: 'Forum', params: { dpcId: id } })
    },
    refresh() {
      if (!this.refreshing) {
        this.getListe();
      }
    },
  },
  mounted() {
    this.getListe();
  },
  mixins: [
    parseHtmlMixin,
  ],
}
</script>

<style lang="scss" scoped>
.v-card {
  .v-image {
    .container {
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgb(0, 0, 0, 0.6);
      color: #FFFFFF;

      h3 {
        font-size: 1.2rem;
        line-height: normal;
        font-weight: bold;
        display: block;
      }
    }
  }

  .v-alert {
    font-size: 0.7rem;
    padding: 1rem;
  }

  .v-card__text {
    border: none;
  }
}
</style>

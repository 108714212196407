<template>
  <Animated 
    enter="fadeIn" 
    leave="fadeOut"
  >
    <v-container 
      v-if="!loading && !completed" 
      fluid 
      class="dpc-container mb-4"
    >
      <v-stepper 
        v-model="stepper" 
        class="elevation-0"
      >
        <v-stepper-header>
          <template v-for="(step,index) in steps">
            <v-stepper-step
              :complete="stepper > index + 1"
              :step="index + 1"
              :key="`${index + 1}-step`"
            >
              {{ step.titre }}
            </v-stepper-step>

            <v-divider
              v-if="index + 1 < steps.length"
              :key="`${index + 1}-divider`"
            />
          </template>
        </v-stepper-header>    
      </v-stepper>

      <Animated 
        enter="slideInRight" 
        leave="slideOutLeft"
      >
        <step-component 
          v-if="stepData" 
          :step="stepData" 
          class="mt-5" 
        />
      </Animated>

      <v-container 
        fluid 
        v-if="!stepData" 
        class="d-flex justify-center pa-12"
      >
        <v-icon 
          color="primary" 
          large
        >
          mdi-loading mdi-spin
        </v-icon>
      </v-container>
    </v-container>

    <v-container 
      v-if="!loading && completed" 
      fluid 
      class="dpc-container"
    >
      <recap-component />
    </v-container>
  </Animated>
</template>

<script>
import stepComponent  from './components/steps/StepComponent'
import recapComponent from './components/recap/Recap'
import api from './apis/Api'

export default{
  data() {
    return {
      sendingKeepAlive: false,
    }
  },
  components: {
    stepComponent,
    recapComponent,
  },
  computed: {
    loading(){
      return this.$store.getters['dpc/isLoading'];
    },
    sessionLogId(){
      return this.$store.getters['dpc/getSessionLogId'];
    },
    documentTitle(){
      return this.$store.getters['dpc/getDocumentTitle'];
    },
    titre(){
      return this.$store.getters['dpc/getTitre'];
    },
    steps(){
      return this.$store.getters['dpc/getSteps'];
    },
    completed(){
      return this.$store.getters['dpc/isCompleted'];
    },
    stepper(){
      return this.$store.getters['dpc/getStepper'];
    },
    stepData(){
      return this.$store.getters['dpc/getStepData'];
    },
  },
  methods: {
    updateSessionLog() {
      setTimeout(() => {
        if (!this.sendingKeepAlive){
          this.sendingKeepAlive = true;

          api.updateSessionLog(this.sessionLogId)
          .then( () => {
            this.sendingKeepAlive = false;
            this.updateSessionLog();
          })
          .catch(() => {
            this.sendingKeepAlive = false;
            this.updateSessionLog();
          });

        }
      }, 60000);
    }
  },
  watch:{
    documentTitle() {
      document.title = this.documentTitle;
    },
    sessionLogId(){
      if (this.sessionLogId > 0){
        this.updateSessionLog();
      }
    }
  },
  beforeMount(){
    this.$store.dispatch('dpc/init');
    this.$store.dispatch('appbar/hideMenu');
    this.$store.dispatch('dpc/getParams',this.$route.params.id);
    this.showStepComponent = false;
  },
  mounted(){
    this.$store.dispatch('scrollToTop');
  }
}
</script>

<style>
.dpc-container{
  padding-top:80px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.v-card__text, .v-card__title {
  word-break: normal; 
}

.v-label.theme--light.v-label--is-disabled{
    color: rgba(0, 0, 0, 0.38);
}
.v-input__control .v-messages{
    margin-top: 10px;
}
.v-input--selection-controls.v-input--checkbox{
  margin-top: 0;
}
.v-input--checkbox .v-input__control .v-messages{
    margin-top: 0;
}
.v-input--checkbox .v-input__control .v-messages .v-messages__wrapper .v-messages__message:last-child{
    margin-bottom: 10px;
}
</style>
<template>
  <GChart
    type="PieChart"
    :options="options"
    :data="data"
    v-if="display"
  />
</template>
 
<script>
import { GChart } from 'vue-google-charts'

export default {
  components: {
    GChart
  },
  props: {
    titre: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    }
  },
  computed: {
    display(){
      for (let index = 0; index < this.data.length; index++) {
        if (parseInt(this.data[index][1]) > 0) return true;
      }
      return false;
    },
    options() {
      return {
        title: this.titre,
        backgroundColor: 'transparent',
        height: 300,
        width: 500,
        // legend: {
        //   position: 'bottom',
        // },
        titleTextStyle: {
          fontSize: 14,
        },
        colors: [
          '#43A047',
          '#FB8C00',
          '#FF5252',
          '#1565C0',
          '#E040FB',
          '#D4E157',
        ],
      };
    },
  },
};
</script>
<template>
  <v-card class="elevation-0 mb-10 card-othertopic mr-4">
    <div class="d-flex">
      <v-avatar
        class="mr-6 white--text font-weight-bold"
        size="40"
        :color="generateColor(topic.initials)"
      >
        {{ topic.initials }}
      </v-avatar>

      <div class="flex-grow-1">
        <div class="body-2 font-weight-bold">
          <a 
            class="black--text" 
            @click="gotoTopic"
          >{{ topic.sujet }}</a>
        </div>
        <div class="d-flex justify-space-between mt-2">
          <div class="body-2">
            {{ topic.user }}
          </div>
          <div class="body-2">
            <v-icon size="20">
              mdi-comment-outline
            </v-icon>
            {{ topic.comments }}
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import colorAvatarMixin from '@/mixins/colorAvatar'
export default {
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  methods: {
    gotoTopic(){
      this.$emit('gotoTopic', { topicId :  this.topic.id});
    },
  },
  mixins: [
    colorAvatarMixin,
  ],
}
</script>


<template>
  <v-container
    fluid
    class="py-0"
  >
    <dpc-en-cours />
    <dpc-disponibles />
    <dpc-complete />
  </v-container>
</template>

<script>

import DpcEnCours from './encours/DpcEnCours.vue'
import DpcDisponibles from './disponibles/DpcDisponibles.vue'
import DpcComplete from './complete/DpcComplete.vue'

export default {
  beforeMount() {
    this.$store.dispatch('toolbar/disable');
    this.$store.dispatch('auth/clearErrors');
    this.$store.dispatch('appbar/showMenu');
  },
  components: {
    DpcEnCours,
    DpcDisponibles,
    DpcComplete,
  },
  mounted() {
    this.$store.dispatch('scrollToTop');
    document.title = 'DPC ARMP - Tableau de bord';
  },
}

</script>

import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
    async getCurrent(sessionId){
        await Csrf.getCookie();
        return Api.post(`/dpc/course/current`,{
            'id':sessionId
        });
    },

    async gotoNext(sessionId,currentStep){
        await Csrf.getCookie();
        return Api.post(`/dpc/course/gotoNext`,{
            'id':sessionId,
            'current':currentStep
        });
    },

    async saveForm(sessionId,currentStep,form){
        await Csrf.getCookie();
        return Api.post(`/dpc/course/saveForm`,{
            'id':sessionId,
            'current'   : currentStep,
            'answers'   : form.answers,
            'evaluation' : form.evaluation
        });
    },

}
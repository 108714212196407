<template>
  <v-card class="elevation-0 mt-5 card-topic">
    <div class="mb-4">
      <div class="d-flex flex-row justify-space-between grow align-start">
        <h4 class="h3 font-weight-bold">
          {{ topic.sujet }}
        </h4>
        <v-tooltip 
          left 
        >
          <template 
            v-slot:activator="{ on, attrs }"
          >
            <v-btn
              fab
              small
              :color="topic.notifications ? 'success' : 'grey'"
              class="elevation-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                color="white"
                @click="setNotification"
              >
                {{ topic.notifications ? 'mdi-bell-ring-outline' : 'mdi-bell-off-outline' }}
              </v-icon>
            </v-btn>
          </template>
          <span>Cliquer pour {{ topic.notifications ? 'désactiver' : 'activer' }} la notification par mail</span>
        </v-tooltip>
      </div>
    </div>
    <div class="d-flex">
      <v-avatar
        class="mr-6 white--text font-weight-bold text-h5"
        size="60"
        :color="generateColor(topic.initials)"
      >
        {{ topic.initials }}
      </v-avatar>
      <div>
        <div class="body-2">
          <span class="font-weight-bold">{{ topic.user }}</span>, {{ topic.date
          }}
        </div>
        <div
          class="body-2 mt-4"
          v-html="topic.message"
        />
      </div>
    </div>
  </v-card>
</template>

<script>
import colorAvatarMixin from '@/mixins/colorAvatar'
export default {
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setNotification() {
      if (this.topic.notifications) {
        this.$emit('unsubscribeTopic', { topicId: this.topic.id });
      } else {
        this.$emit('subscribeTopic', { topicId: this.topic.id });
      }
    },
  },
  mixins: [
    colorAvatarMixin,
  ],
}
</script>

<style>
.card-topic p{
  margin-bottom: 8px;
}
</style>

const state = {

    showMenu: true

};
const getters = {

    isMenuOn: (state) => state.showMenu,

};
const actions = {
    init({commit}){
        commit('setShowMenu', true);
    },
    hideMenu({commit}){
        commit('setShowMenu', false);
    },
    showMenu({commit}){
        commit('setShowMenu', true);
    },
};
const mutations = {
    setShowMenu(state,value) {
        state.showMenu = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

<template>
  <v-container 
    fluid 
    class="forum-topic pa-8"
  >
    <div v-if="dpc.id > 0">
      <div class="d-flex justify-space-between">
        <div>
          <h2 class="primary--text">
            {{ dpc.titre }}
          </h2>
          <p class="body-2">
            <span 
              class="mr-10" 
              v-if="dpc.ref.trim() != ''"
            >Référence ANDPC : {{ dpc.ref }}</span> 
            <!-- <span
              v-if="dpc.session.trim() != ''"
            >Session : {{ dpc.session }}</span> -->
          </p>
        </div>
        <div class="d-flex justify-end ml-10">
          <v-btn 
            icon 
            :disabled="!loaded" 
            @click="refresh"
          >
            <v-icon v-if="!loaded">
              mdi-loading mdi-spin
            </v-icon>
            <v-icon 
              v-else 
              class="align-self-start"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </div>
      </div>

      <TopicCard 
        :topic="topic" 
        @subscribeTopic="subscribeTopic" 
        @unsubscribeTopic="unsubscribeTopic" 
      />

      <v-row 
        class="mt-10 ml-10 pl-8"
      >
        <v-col 
          cols="12" 
          lg="7" 
          xl="8" 
          class="pr-4 pr-lg-16"
        >
          <div v-if="showForm">
            <h3 class="mb-10">
              Ajouter un commentaire :
            </h3>

            <Form
              :key="'form-' + formKey"
              class="mb-12" 
              @saveComment="saveComment" 
              @toggleForm="toggleForm" 
            />
          </div>
          
          <v-btn 
            class="px-3 mr-5 mb-10" 
            color="primary" 
            depressed 
            @click="toggleForm"
            v-else
          >
            <template>
              Ajouter un commentaire
            </template>
            <v-icon 
              right 
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>

          <h3 class="mb-10">
            {{ comments.length }} commentaires :
          </h3>

          <CommentCard 
            v-for="comment in comments" 
            :key="'comment-' + comment.id" 
            :comment="comment" 
          />
        </v-col>

        <v-col 
          cols="12" 
          lg="5" 
          xl="4"
        >
          <h3 class="mb-10">
            Autres sujets les plus commentés :
          </h3>
          <OtherCard 
            v-for="otherTopic in otherTopics" 
            :key="'other-' + otherTopic.id" 
            :topic="otherTopic" 
            @gotoTopic="gotoTopic" 
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
  
<script>
import api from './api'
import TopicCard from './TopicCard'
import CommentCard from './CommentCard'
import OtherCard from './OtherCard'
import Form from './Form'

export default {
  data() {
    return {
      formKey: 1,
      loaded: false,
      showForm: false,
      dpc: {},
      topic: {},
      comments: [],
      otherTopics: [],
    };
  },
  components: {
    TopicCard,
    CommentCard,
    OtherCard,
    Form,
  },
  methods: {
    toggleForm() {
      this.showForm = !this.showForm;
    },
    gotoTopic(payload) {
      this.$router.replace({ name: 'ForumTopic', params: { dpcId: this.dpc.id, topicId: payload.topicId } }).catch(() => {});
    },
    saveComment(payload) {
      this.$store.dispatch('overlay/enable',null,{root: true});
      api.saveComment(this.dpc.id, this.topic.id, payload.message)
        .then(() => {
          this.formKey += 1;
          this.getComments();
          this.$store.dispatch('overlay/disable',null,{root: true});
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });

    },
    setNotification() {
      if (this.topic.notifications) {
        this.unsubscribeTopic();
      } else {
        this.subscribeTopic();
      }
    },
    // SubscribeToTopic
    subscribeTopic() {
      api.subscribeTopic(this.dpc.id, this.topic.id)
        .then(() => {
          this.refresh().then(() => {
            this.$store.dispatch('overlay/setMessages', [{ type: 'success', message: 'Vous allez maintenant recevoir les notifications.' }]);
          });
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });

    },
    // UnsubscribeToTopic
    unsubscribeTopic() {
      api.unsubscribeTopic(this.dpc.id, this.topic.id)
        .then(() => {
          this.refresh().then(() => {
            this.$store.dispatch('overlay/setMessages', [{ type: 'success', message: 'Vous n\'allez plus recevoir de notification.' }]);
          });
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });

    },
    // Refresh Comments
    async refresh() {
      this.getDpc();
      this.getComments();
    },
    // Get DPC
    getDpc() {
      this.$store.dispatch('overlay/enable');
      api.getTopic(this.$route.params.dpcId, this.$route.params.topicId)
        .then(response => {
          if (response.data) {
            this.dpc = response.data.dpc;
            this.topic = response.data.topic;
            this.otherTopics = response.data.autres;
          }
          this.$store.dispatch('overlay/disable');
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });
    },
    // Get Comments
    getComments() {
      this.loaded = false;
      api.getComments(this.$route.params.dpcId, this.$route.params.topicId)
        .then(response => {
          if (response.data) {
            this.comments = response.data.comments;
          }
          this.loaded = true;
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
          this.loaded = true;
        });
    },
  },
  beforeMount() {
    this.$store.dispatch('appbar/hideMenu', null, { root: true });
    this.$store.dispatch('overlay/enable', null, { root: true });
    this.$store.dispatch('toolbar/enable', { titre: 'Forum de discussion', backRouteName: 'Forum', backRouteTooltip: 'Retour à la liste des sujets de discussion', backRouteParams: { dpcId: this.$route.params.dpcId } }, { root: true });
  },
  mounted() {
    this.getDpc();
  },
  watch: {
    topic() {
      if (this.topic.id > 0) {
        document.title = this.dpc.titre;
        this.getComments();
      }
    }
  }
};

</script>
  
<style>
.forum-topic {
  margin-top: 60px;
}
</style>
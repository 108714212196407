<template>
  <v-footer
    flat
    tile
    dark
    color="grey lighten-1"
  >
    <v-container>
      <v-card
        flat
        tile
        class="transparent text-center"
      >
        <v-card-text>
          <p>
            <a 
              class="link" 
              href="https://www.armp-asso.fr/documents/cgu.pdf" 
              target="_blank"
            >CGU</a> |
            <a 
              class="link" 
              href="https://www.armp-asso.fr/documents/politique-de-confidentialite.pdf" 
              target="_blank"
            >Politique de confidentialité</a> |
            <a 
              class="link" 
              href="https://www.armp-asso.fr/documents/politique-cookies.pdf" 
              target="_blank"
            >Politique cookies</a>
          </p>
          <p>© 2024 ARMP - Tous droits réservés. [ v1.6.0 ]</p>
        </v-card-text>
      </v-card>
    </v-container>
  </v-footer>
</template>


<script>
  export default {
    name: 'Footer',
    data: () => ({
      site_url : process.env.VUE_APP_SITE_URL,
    }),
  }
</script>

<style lang="scss">
.v-footer{
  p{
    color: #515151;
    font-weight: normal;
    font-size: 0.8rem;
  }
  a.link{
    color: #515151;
    text-decoration: none;
    &:hover{
      color: #515151;
    }
  }
}
</style>
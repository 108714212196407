<template>
  <v-container 
    fluid 
    class="forum-container pa-8"
  >
    <div v-if="dpc.id > 0">
      <div class="d-flex justify-space-between">
        <div>
          <h2 class="primary--text">
            {{ dpc.titre }}
          </h2>
          <p class="body-2">
            <span 
              class="mr-10" 
              v-if="dpc.ref.trim() != ''"
            >Référence ANDPC : {{ dpc.ref }}</span> 
            <!-- <span
              v-if="dpc.session.trim() != ''"
            >Session : {{ dpc.session }}</span> -->
          </p>
        </div>
        <div 
          class="d-flex justify-end ml-10" 
          v-if="!showForm"
        >
          <v-btn 
            class="px-3 mr-5" 
            color="primary" 
            depressed 
            @click="toggleForm"
          >
            <template>
              Ajouter un sujet de discussion
            </template>
            <v-icon 
              right 
              dark
            >
              mdi-plus
            </v-icon>
          </v-btn>

          <v-btn 
            icon 
            :disabled="!loaded" 
            @click="refresh"
          >
            <v-icon v-if="!loaded">
              mdi-loading mdi-spin
            </v-icon>
            <v-icon 
              v-else 
              class="align-self-start"
            >
              mdi-refresh
            </v-icon>
          </v-btn>
        </div>
      </div>

      <Form 
        v-if="showForm" 
        :key="'form-' + formKey" 
        @saveSubject="saveSubject" 
        @toggleForm="toggleForm" 
      />

      <v-container 
        fluid 
        class="pa-0 mt-2" 
        v-if="!showForm"
      >
        <v-row>
          <v-col 
            v-for="topic in topics" 
            :key="'topic-' + topic.id" 
            cols="12" 
            sm="12" 
            md="6" 
            lg="6" 
            xl="6"
          >
            <TopicCard 
              :topic="topic" 
              @subscribeTopic="subscribeTopic" 
              @unsubscribeTopic="unsubscribeTopic"
              @gotoTopic="gotoTopic" 
            />
          </v-col>
          <div 
            class="grow px-2" 
            v-if="topics.length == 0 && loaded"
          >
            <v-alert 
              type="info" 
              outlined
            >
              Aucun sujet de discussion n'est disponible.
            </v-alert>
          </div>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>
  
<script>
import api from './api'
import TopicCard from './TopicCard'
import Form from './Form'

export default {
  data() {
    return {
      formKey: 1,
      loaded: false,
      showForm: false,
      dpc: {},
      topics: [],
    };
  },
  components: {
    TopicCard,
    Form,
  },
  methods: {
    saveSubject(payload) {
      this.$store.dispatch('overlay/enable',null,{root: true});
      api.saveSubject(this.dpc.id, payload.sujet, payload.message)
        .then(() => {
          this.formKey += 1;
          this.toggleForm();
          this.getTopics();
          this.$store.dispatch('overlay/disable',null,{root: true});
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });

    },
    toggleForm() {
      this.showForm = !this.showForm;
    },
    gotoTopic(payload) {
      this.$router.push({ name: 'ForumTopic', params: { dpcId: this.dpc.id, topicId: payload.topicId } })
    },
    // SubscribeToTopic
    subscribeTopic(payload) {
      api.subscribeTopic(this.dpc.id, payload.topicId)
        .then(() => {
          this.refresh().then(() => {
            this.$store.dispatch('overlay/setMessages', [{ type: 'success', message: 'Vous allez maintenant recevoir les notifications.' }]);
          });
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });

    },
    // UnsubscribeToTopic
    unsubscribeTopic(payload) {
      api.unsubscribeTopic(this.dpc.id, payload.topicId)
        .then(() => {
          this.refresh().then(() => {
            this.$store.dispatch('overlay/setMessages', [{ type: 'success', message: 'Vous n\'allez plus recevoir de notification.' }]);
          });
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });

    },
    // Refresh Topics
    async refresh() {
      this.getTopics();
    },
    // Get DPC
    getDpc() {
      this.$store.dispatch('overlay/enable');
      api.getDpc(this.$route.params.dpcId)
        .then(response => {
          if (response.data) {
            this.dpc = response.data.dpc;
          }
          this.$store.dispatch('overlay/disable');
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
        });
    },
    // Get Topics
    getTopics() {
      this.loaded = false;
      api.getTopics(this.$route.params.dpcId)
        .then(response => {
          // this.$store.dispatch('overlay/disable');
          if (response.data) {
            this.topics = response.data.topics;
          }
          this.loaded = true;
        })
        .catch(error => {
          if (error.response) {
            // client received an error response (5xx, 4xx)
            if (error.response.status === 401) {
              this.$store.dispatch('auth/logoutUser');
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Votre session a expirée.' }]);
            } else {
              this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Erreur de connexion. Merci de réessayer.' }]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages', [{ type: 'error', message: 'Une erreur s\'est produite. Merci de réessayer.' }]);
          }
          this.loaded = true;
        });
    },
  },
  beforeMount() {
    this.$store.dispatch('appbar/hideMenu', null, { root: true });
    this.$store.dispatch('overlay/enable', null, { root: true });
    this.$store.dispatch('toolbar/enable', { titre: 'Forum de discussion', backRouteName: 'Dashboard', backRouteTooltip: 'Retour au tableau de bord' }, { root: true });
  },
  mounted() {
    this.getDpc();
  },
  watch: {
    dpc() {
      if (this.dpc.id > 0) {
        document.title = this.dpc.titre;
        this.getTopics();
      }
    }
  }
};

</script>
  
<style>
.forum-container {
  margin-top: 60px;
}
</style>
<template>
  <div>
    <v-navigation-drawer 
      v-model="sidebar" 
      app 
      temporary 
      v-if="showMenu"
    >
      <v-list>
        <v-list-item
          v-for="item in activeMenuItems"
          :key="item.title"
          :to="item.path"
          :href="item.href"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <Animated 
      enter="slideInDown" 
      leave="slideOutUp"
    >
      <v-app-bar 
        dark 
        app 
        v-if="showMenu"
        flat
        color="primary"
      >
        <span class="hidden-md-and-up">
          <v-app-bar-nav-icon 
            @click.stop="sidebar = !sidebar"
          />
        </span>
        
        <v-toolbar-title class="d-flex justify-start align-center"> 
          <router-link 
            to="/" 
            tag="span" 
            style="cursor: pointer"
          >
            <v-img 
              src="@/assets/logo-armp.svg" 
              max-height="50"
              contain
              width="100"
            />
          </router-link>
          <span>Association des Rencontres Médicales Pluridisciplinaires</span>
        </v-toolbar-title>

        <v-spacer />
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn 
            text
            v-for="item in activeMenuItems"
            :key="item.title"
            :to="item.path || ''"
            :href="item.href || ''"
          >
            <v-icon 
              left 
              dark
            >
              {{ item.icon }}
            </v-icon>
            {{ item.title }}
          </v-btn>
        </v-toolbar-items>
      </v-app-bar>
    </Animated>
  </div>
</template>

<script>
export default {
  data: () => ({
    sidebar: false,
    menuItems: [
      // { title: 'Accueil', href: process.env.VUE_APP_SITE_URL, icon: 'home', access: 'public' },
      // { title: 'Tableau de bord', path: '/', icon: 'dashboard', access: 'registered' },
      { title: 'Inscription', path: '/inscription', icon: 'how_to_reg', access: 'guest' },
      { title: 'Connexion', path: '/connexion', icon: 'lock', access: 'guest' },
      { title: 'Déconnexion', path: '/deconnexion', icon: 'lock', access: 'registered' },
    ]
  }),
  computed: {
    showMenu(){
      return this.$store.getters['appbar/isMenuOn'];
    },
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    activeMenuItems: function() {
      if (this.isLoggedIn){
        return this.menuItems.filter(function(m) {
          return m.access == 'public' || m.access == 'registered';
        })
      } else {
        return this.menuItems.filter(function(m) {
          return m.access == 'public' || m.access == 'guest';
        })
      }
    },
  },
}
</script>
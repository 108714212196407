import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
    async getListe(){
        await Csrf.getCookie();
        return Api.get(`/dashboard/dpc/encours`);
    },

    async delete(id){
        await Csrf.getCookie();
        return Api.post(`/dashboard/dpc/encours/delete`,{'id':id});
    },

}
import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
  // Get Topic
  async getTopic(dpcId, topicId){
    await Csrf.getCookie();
    return Api.get('/forum/' + dpcId + '/topic/' + topicId);
  },

  // Get Comments
  async getComments(dpcId, topicId){
    await Csrf.getCookie();
    return Api.get('/forum/' + dpcId + '/topic/' + topicId + '/comments');
  },

  //  Subscribe to topic
  async subscribeTopic(dpcId, topicId){
    await Csrf.getCookie();
    return Api.post('/forum/' + dpcId + '/subscribe',{'id':topicId});
  },

  //  Unsubscribe from topic
  async unsubscribeTopic(dpcId, topicId){
    await Csrf.getCookie();
    return Api.post('/forum/' + dpcId + '/unsubscribe',{'id':topicId});
  },

  //  Save comment
  async saveComment(dpcId, topicId, message){
    await Csrf.getCookie();
    return Api.post('/forum/' + dpcId + '/topic/' + topicId + '/comment',{'message':message});
  },

}
<template>
  <v-card 
    outlined 
    class="pa-md-4 pa-lg-5"
  >
    <v-card-title 
      class="d-block primary white--text rounded"
    >
      <h3>Résultats</h3>
    </v-card-title>
    
    <v-card-text 
      v-if="results"
      style="font-size:inherit;" 
      class="pt-10"
      v-html="parseHtml(results.intro)"
    />

    <template v-if="results">
      <v-card-subtitle class="d-block grey darken-2 white--text rounded px-4 py-2 mt-4">
        <h3>Synthèse de vos réponses pour chaque section</h3>
      </v-card-subtitle>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Section
              </th>
              <th class="text-center">
                No. de questions
              </th>
              <th class="text-center">
                Bonnes réponses
              </th>
              <th class="text-center">
                % Bonnes réponses
              </th>
              <th class="text-center">
                Degré de certitude
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="dpc in results.dpc">
              <tr 
                v-if="dpc.maxscore > 0" 
                :key="dpc.id"
              >
                <td class="text-left">
                  {{ dpc.titre }}
                </td>
                <td class="text-center">
                  {{ dpc.maxscore }}
                </td>
                <td class="text-center">
                  {{ dpc.score }}
                </td>
                <td class="text-center">
                  {{ Math.round(dpc.score / dpc.maxscore * 100) }}%
                </td>
                <td class="text-center">
                  {{ dpc.evaluation }}%
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <th class="text-left">
                {{ tableData.footer[0] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[1] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[2] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[3] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[4] }}
              </th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>

      <v-alert
        border="top"
        color="green lighten-1"
        dark
        v-if="results.delay"
        class="my-10 text-center"
        icon="mdi-clock"
      >
        Vous pourrez procéder à la dernière étape du DPC {{ results.delay_info }}. Vous retrouverez l'audit clinique initial avec les mêmes questions que vous pourrez appliquer sur de nouveaux patients.
      </v-alert>
      
      <div class="mt-4 text-center">
        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="warning"
          depressed
          @click="reset"
        >
          <v-icon class="mr-1">
            mdi-chevron-left
          </v-icon>
          Refaire le DPC 
        </v-btn>
        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="info"
          depressed
          @click="saveTable"
        >
          <v-icon class="mr-1">
            mdi-file-pdf-outline
          </v-icon>
          Synthèse de vos réponses pour chaque section 
        </v-btn>
        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="info"
          depressed
          :href="downloadDocsUrl"
        >
          <v-icon class="mr-1">
            mdi-file-pdf-outline
          </v-icon>
          Tous les « Essentiels » 
        </v-btn>

        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="success"
          depressed
          @click="continuer"
          :disabled="!!results.delay"
        >
          Continuer 
          <v-icon class="ml-1">
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </div>
    </template>

    <v-card-text 
      v-else 
      class="text-center mt-4"
    >
      <v-icon 
        color="primary" 
        large
      >
        mdi-loading mdi-spin
      </v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import parseHtmlMixin  from '@/mixins/parseHtml'
import sanitizeFilename from '@/mixins/sanitizeFilename'

export default {
  computed:{
    results(){
      return this.$store.getters['dpc/getResults'];
    },
    tableData(){

      let table = {};
      table.header = ['Section','No. de questions','Bonnes réponses','% Bonnes réponses','Degré de certitude'];

      let data = [];
      let totalQuestions = 0;
      let totalScore = 0;
      let counter = 0;
      let totalEvaluation = 0;
      
      this.results.dpc.forEach(function(element) { 
        if (element.maxscore > 0){
          counter++;
          totalQuestions = totalQuestions + element.maxscore;
          totalScore = totalScore + element.score;
          totalEvaluation = totalEvaluation + element.evaluation;
          data.push([
            element.titre,
            element.maxscore,
            element.score,
            Math.round(element.score / element.maxscore * 100) + '%',
            element.evaluation + '%'
          ]);
        }
      });

      table.footer = [
        '',
        totalQuestions,
        totalScore,
        Math.round(totalScore / totalQuestions * 100) + '%',
        Math.round(totalEvaluation / counter) + '%'
      ];

      table.data = data;

      return table;
    },
    downloadDocsUrl(){
      return process.env.VUE_APP_URL + '/dpc/' + this.$store.getters['dpc/getSessionId'] + '/downloadAll';
    },
  },
  methods:{
    continuer(){
      this.$store.dispatch('dpc/saveResults');
    },
    reset(){
      this.$confirm('Vous perdrez l’ensemble de vos réponses sur la partie cognitive de ce programme DPC (avec conservation des données patients de l\'étape EPP). Souhaitez-vous continuer ?',{
        buttonTrueText: 'Oui',
        buttonFalseText: 'Annuler',
        buttonTrueColor: 'error',
        buttonFalseColor: 'default',
        buttonTrueFlat: true,
        buttonFalseFlat: false,
        color: 'warning',
        icon: 'warning',
        title: 'Attention !',
      }).then(res => {
        if (res){
          this.$store.dispatch('dpc/resetResults');
        }
      })
    },
    saveTable() {

      const doc = new jsPDF('l', 'pt', 'A4')

      doc.setFontSize('16');
      doc.text('DPC : ' + this.$store.getters['dpc/getTitre'], 40, 50);

      doc.setFontSize('12');
      doc.text(this.$store.getters['auth/getUsername'], 40, 70);

      doc.autoTable({
        head: [this.tableData.header],
        foot: [this.tableData.footer],
        body: this.tableData.data,
        margin: { top: 85 },
        styles: {
          halign: 'center',
        },
        columnStyles: { 
          0: { halign: 'left' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' },
        },
      })

      let filename = this.sanitizeFilename('synthese-reponses-' + this.$store.getters['dpc/getTitre']);
      doc.save(filename);

    },
  },
  mounted(){
    this.$store.dispatch('dpc/getResults');
  },
  mixins: [
    parseHtmlMixin,
    sanitizeFilename,
  ],
}
</script>

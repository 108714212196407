
const state = {
  update: false,
};
const getters = {
  shouldUpdate: (state) => state.update,
};
const actions = {

  setUpdate({commit},value){
    commit('SET_UPDATE', value);
  },

};
const mutations = {
    SET_UPDATE(state,value) {
        state.update = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

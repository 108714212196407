
import Inscription  from './Inscription.vue'

export const InscriptionRoutes = [

  {
    path: '/inscription',
    name: 'Inscription',
    component: Inscription,
    meta: { 
      title: 'Formuaire d\'inscription',
      requiresGuest: true
    }
  },

]
<template>
  <div>
    <v-textarea
      :required="required"
      :disabled="disabled"
      rows="2"
      v-model="value"
      :rules="rules"
      class="rounded"
      outlined
    />
  </div>
</template>

<script>
export default {
    props: {
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        value: null
    }),
    computed: {
        rules: function () {
            if (this.disabled || !this.required) {
                return [];
            } else {
                return [v => (v || '' ).trim().length > 0 || 'Ce champ est obligatoire.']
            }
        }
    },
    watch: {
        value() {
            this.$emit('input', this.value);
        },
        disabled() {
            if (this.disabled) {
                this.value = null;
            }
        }
    },
}
</script>
<template>
  <v-card
    outlined
    class="pa-md-4 pa-lg-5"
  >
    <v-card-title class="d-block primary white--text rounded">
      <h3>Audit Clinique - Comparaison avec vos confrères</h3>
    </v-card-title>

    <v-card-text
      v-for="(section, sectionIndex) in sections"
      :key="sectionIndex"
    >
      <template v-if="section.type == 'html'">
        <div v-html="parseHtml(section.html)" />
      </template>

      <template>
        <h3
          class="my-10"
          v-if="section.titre"
        >
          {{ section.titre }}
        </h3>

        <div
          v-for="(question, subSectionIndex) in section.questions"
          :key="subSectionIndex"
        >
          <div
            class="pa-6 rounded-lg mb-5"
            :class="question.type == 'radio' || question.type == 'checkboxgroup' ? 'light-blue lighten-5' : 'grey lighten-3'"
          >
            <p class="mb-3">
              <span class="font-weight-bold">{{ question.question }}</span> <em
                v-if="!(question.type == 'radio' || question.type == 'checkboxgroup')"
              >(question ouverte)</em>
            </p>
            <div
              v-if="question.type == 'radio' || question.type == 'checkboxgroup'"
              class="d-flex justify-start"
            >
              <GoogleChart
                titre="Moi"
                :data="step.stats.user[sectionIndex][subSectionIndex]"
              />
              <GoogleChart
                titre="Mes confrères"
                :data="step.stats.others[sectionIndex][subSectionIndex]"
              />
            </div>
          </div>
        </div>
      </template>
    </v-card-text>
    <v-card-actions class="justify-center py-10">
      <Animated
        enter="fadeIn"
        leave="fadeOut"
        :duration="{ enter: 1000, leave: 0 }"
      >
        <v-btn
          depressed
          @click="continuer"
          large
          class="my-2 mx-2 px-5"
          color="success"
        >
          Continuer
          <v-icon class="ml-2">
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </Animated>
    </v-card-actions>
  </v-card>
</template>

<script>
import parseHtmlMixin from '@/mixins/parseHtml'
import GoogleChart from './GoogleChart'

export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sections() {
      return this.step.sections;
    },
  },
  components: {
    GoogleChart,
  },
  methods: {
    continuer() {
      this.$store.dispatch('dpc/gotoEppComments');
    }
  },
  mixins: [
    parseHtmlMixin,
  ],
}
</script>

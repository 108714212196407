const state = {
    titre: '',
    visible: false,
    backRouteName: 'Dashboard',
    backRouteParams: {},
    backRouteTooltip: 'Retour au tableau de bord',
};

const getters = {
    getTitre: (state) => state.titre,
    getBackRouteTooltip: (state) => state.backRouteTooltip,
    getBackRouteName: (state) => state.backRouteName,
    getBackRouteParams: (state) => state.backRouteParams,
    showToolbar: (state) => state.visible
};

const actions = {
    init({ commit }) {
        commit('SET_TITRE', '');
        commit('HIDE_TOOLBAR');
    },
    enable({ commit }, { titre, backRouteName = 'Dashboard', backRouteTooltip = 'Retour au tableau de bord', backRouteParams = {} }) {
        commit('SET_TITRE', titre);
        commit('SET_BACKROUTETOOLTIP', backRouteTooltip);
        commit('SET_BACKROUTENAME', backRouteName);
        commit('SET_BACKROUTEPARAMS', backRouteParams);
        commit('SHOW_TOOLBAR');
    },
    disable({ commit }) {
        commit('HIDE_TOOLBAR');
    }
};

const mutations = {
    SET_TITRE(state, titre) {
        state.titre = titre;
    },
    SET_BACKROUTETOOLTIP(state, backRouteTooltip) {
        state.backRouteTooltip = backRouteTooltip;
    },
    SET_BACKROUTENAME(state, backRouteName) {
        state.backRouteName = backRouteName;
    },
    SET_BACKROUTEPARAMS(state, backRouteParams) {
        state.backRouteParams = backRouteParams;
    },
    SHOW_TOOLBAR(state) {
        state.visible = true;
    },
    HIDE_TOOLBAR(state) {
        state.visible = false;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
    
    async getListe(){
        await Csrf.getCookie();
        return Api.get(`/dashboard/dpc/complete`);
    },

}
<template>
  <div>
    <!-- Type : intro -->
    <template v-if="step.type == 'intro'">
      <v-card 
        outlined 
        class="pa-md-4 pa-lg-5"
      >
        <v-card-title class="d-block primary white--text rounded">
          <h3>Déroulé du DPC : les différentes phases</h3>
        </v-card-title>
        <v-card-text 
          style="font-size:inherit;" 
          class="pt-5"
        >
          <div 
            class="my-5" 
            v-html="parseHtml(step.contenu)"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn 
            large 
            class="my-10 mx-2 px-5" 
            @click="startDpc" 
            color="success"
            depressed
          >
            Commencer le programme 
            <v-icon 
              class="ml-2"
            >
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>

    <!-- Type : epp1 -->
    <template v-if="step.type == 'epp1' || step.type == 'epp2'">
      <epp-grille :step="step" />
    </template>

    <!-- Type : eppresults -->
    <template v-if="step.type == 'eppresults'">
      <epp-stats :step="step" />
    </template>

    <!-- Type : eppcomments -->
    <template v-if="step.type == 'eppcomments'">
      <epp-comments :step="step" />
    </template>

    <!-- Type : dpc -->
    <template v-if="step.type == 'dpc'">
      <parcours-dpc :steps="step.steps" />
    </template>

    <!-- Type : resultats -->
    <template v-if="step.type == 'resultats'">
      <resultatsComponent />
    </template>


    <!-- Type : commentaires -->
    <template v-if="step.type == 'bilan'">
      <bilan-component />
    </template>
  </div>
</template>

<script>
import eppGrille    	    from '../epp/EppGrille'
import eppStats   	      from '../epp/EppStats'
import eppComments   	    from '../epp/EppComments'
import parcoursDpc        from '../course/ParcoursDpc'
import bilanComponent     from '../bilan/Bilan'
import resultatsComponent from '../resultats/Resultats'
import parseHtmlMixin     from '@/mixins/parseHtml'

export default {
  props: {
      step: {
          type: Object,
          required: true,
      },
  },
  methods: {
    startDpc(){
        this.$store.dispatch('dpc/startDpc');
    },
  },
  components:{
    eppGrille,
    eppStats,
    eppComments,
    parcoursDpc,
    bilanComponent,
    resultatsComponent,
  },
  mixins: [
    parseHtmlMixin,
  ],
}
</script>

<template>
  <v-card
    color="#f2f2f2"
    class="fill-height d-flex flex-column elevation-0 pa-2"
  >
    <div class="ma-4">
      <div class="d-flex flex-row justify-space-between grow align-start">
        <div class="body-1 font-weight-bold">
          {{ topic.sujet }}
        </div>
        <v-tooltip 
          left 
        >
          <template 
            v-slot:activator="{ on, attrs }"
          >
            <v-btn
              fab
              small
              class="elevation-0"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon @click="setNotification">
                {{ topic.notifications ? 'mdi-bell-ring-outline' : 'mdi-bell-off-outline' }}
              </v-icon>
            </v-btn>        
          </template>
          <span>Cliquer pour {{ topic.notifications ? 'désactiver' : 'activer' }} la notification par mail</span>
        </v-tooltip>
      </div>
    </div>

    <div class="d-flex px-4 mt-2 fill-height">
      <v-avatar
        class="mr-6 white--text font-weight-bold text-h5"
        size="60"
        :color="generateColor(topic.initials)"
      >
        {{ topic.initials }}
      </v-avatar>

      <div class="d-flex flex-column mb-4 fill-height pb-4 flex-grow-1">
        <div class="body-2">
          <span class="font-weight-bold">{{ topic.user }}</span>, {{ topic.date
          }}
        </div>
        <div class="body-2 mt-4">
          {{ topic.message }}
        </div>
        <div class="d-flex align-center justify-start mt-auto">
          <v-icon class="mr-1">
            mdi-comment-outline
          </v-icon>
          <span class="body-2">{{ topic.comments }}</span>
          <v-btn
            fab
            dark
            small
            color="primary"
            class="elevation-0 ml-auto"
            @click="gotoTopic"
          >
            <v-icon dark>
              mdi-arrow-right
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import colorAvatarMixin from '@/mixins/colorAvatar'

export default {
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setNotification() {
      if (this.topic.notifications){
        this.$emit('unsubscribeTopic', { topicId :  this.topic.id});
      } else {
        this.$emit('subscribeTopic', { topicId :  this.topic.id});
      }
    },
    gotoTopic(){
      this.$emit('gotoTopic', { topicId :  this.topic.id});
    }
  },
  mixins: [
    colorAvatarMixin,
  ],
}
</script>
  
import api from './apis/Api'

const state = {
    stepper             : 1,        // DPC Circuit Current Section (step)
    titre               : null,     // Step Title
    parts               : [],       // Array of parts for that section
    answers             : [],       // Final user answers
    evaluation          : 0,        // Final user self eval
    maxScore            : 0,        // Final user max score for that section
    score               : 0,        // Final user score for that section
    enSavoirPlus        : null,     // En Savoir Plus HTML
    enSavoirPlusPdfId   : null,     // En Savoir Plus PDF ID
    enSavoirPlusPdf     : null,     // En Savoir Plus PDF
    enSavoirPlusTimer   : 300,      // Delay to proceed
    submitted           : false,    // Form submission status
};
const getters = {
    getStepper              : (state) => state.stepper,
    getTitre                : (state) => state.titre,
    getParts                : (state) => state.parts,
    getAnswers              : (state) => state.answers,
    getEvaluation           : (state) => state.evaluation,
    getMaxScore             : (state) => state.maxScore,
    getScore                : (state) => state.score,
    getEnSavoirPlus         : (state) => state.enSavoirPlus,
    getEnSavoirPlusPdfId    : (state) => state.enSavoirPlusPdfId,
    getEnSavoirPlusPdf      : (state) => state.enSavoirPlusPdf,
    getEnSavoirPlusTimer    : (state) => state.enSavoirPlusTimer,
    isSubmitted             : (state) => state.submitted,
};
const mutations = {
    SET_STEPPER(state,stepper) {
        state.stepper = stepper;
    },
    SET_TITRE(state,titre) {
        state.titre = titre;
    },
    SET_PARTS(state,parts) {
        state.parts = parts;
    },
    SET_ANSWERS(state,answers) {
        state.answers = answers;
    },
    SET_EVALUATION(state,evaluation) {
        state.evaluation = evaluation;
    },
    SET_MAX_SCORE(state,maxScore) {
        state.maxScore = maxScore;
    },
    SET_SCORE(state,score) {
        state.score = score;
    },
    SET_ENSAVOIRPLUS(state,enSavoirPlus) {
        state.enSavoirPlus = enSavoirPlus;
    },
    SET_ENSAVOIRPLUSPDFID(state,enSavoirPlusPdfId) {
        state.enSavoirPlusPdfId = enSavoirPlusPdfId;
    },
    SET_ENSAVOIRPLUSPDF(state,enSavoirPlusPdf) {
        state.enSavoirPlusPdf = enSavoirPlusPdf;
    },
    SET_ENSAVOIRPLUSTIMER(state,enSavoirPlusTimer) {
        state.enSavoirPlusTimer = enSavoirPlusTimer;
    },
    SET_SUBMITTED(state,submitted) {
        state.submitted = submitted;
    },
};
const actions = {

    init({commit}){
        // commit('SET_STEPPER', 1);
        commit('SET_TITRE', null);
        commit('SET_PARTS', []);
        commit('SET_ANSWERS', []);
        commit('SET_EVALUATION', 0);
        commit('SET_MAX_SCORE', 0);
        commit('SET_SCORE', 0);
        commit('SET_ENSAVOIRPLUS', null);
        commit('SET_ENSAVOIRPLUSPDF', null);
        commit('SET_ENSAVOIRPLUSTIMER', 300);
        commit('SET_SUBMITTED', false);
    },

    // Get current dpc section contents
    getCurrent({dispatch,commit,rootState}){

        dispatch('overlay/enable',null,{root:true});

        dispatch('init');

        let sessionId = rootState.dpc.sessionId;

        api.getCurrent(sessionId)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data){
                commit('SET_STEPPER', response.data.stepper);
                commit('SET_TITRE', response.data.titre);
                commit('SET_PARTS', response.data.parts);
                commit('SET_ANSWERS', response.data.answers);
                commit('SET_EVALUATION', response.data.evaluation);
                commit('SET_MAX_SCORE', response.data.maxScore);
                commit('SET_SCORE', response.data.score);
                commit('SET_ENSAVOIRPLUS', response.data.enSavoirPlus);
                commit('SET_ENSAVOIRPLUSPDFID', response.data.enSavoirPlusPdfId);
                commit('SET_ENSAVOIRPLUSPDF', response.data.enSavoirPlusPdf);
                commit('SET_ENSAVOIRPLUSTIMER', response.data.enSavoirPlusTimer);
                commit('SET_SUBMITTED', response.data.submitted);
                dispatch('scrollToTop',null,{root: true});
            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        })
        .catch(error => {

            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });

    },

    // Proceed to next section (info pages only)
    gotoNext({dispatch,rootState,state}){

        dispatch('overlay/enable',null,{root:true});

        let sessionId = rootState.dpc.sessionId;

        api.gotoNext(sessionId,state.stepper)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data.step){
                dispatch('dpc/getStepData',response.data.step,{root: true});
                // dispatch('dpc/getParams',response.data.step,{root: true});
            } else {
                dispatch('getCurrent');
            }

        })
        .catch(error => {

            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });

    },
    
    // Save form & Proceed to En Savoir Plus (action pages only)
    saveForm({dispatch,rootState,state,commit},form){

        dispatch('overlay/enable',null,{root:true});

        let sessionId = rootState.dpc.sessionId;

        api.saveForm(sessionId,state.stepper,form)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data){

                commit('SET_PARTS', response.data.parts);
                commit('SET_ANSWERS', response.data.answers);
                commit('SET_EVALUATION', response.data.evaluation);
                commit('SET_MAX_SCORE', response.data.maxScore);
                commit('SET_SCORE', response.data.score);
                commit('SET_ENSAVOIRPLUS', response.data.enSavoirPlus);
                commit('SET_ENSAVOIRPLUSPDFID', response.data.enSavoirPlusPdfId);
                commit('SET_ENSAVOIRPLUSPDF', response.data.enSavoirPlusPdf);
                commit('SET_ENSAVOIRPLUSTIMER', response.data.enSavoirPlusTimer);
                commit('SET_SUBMITTED', true);

                dispatch('scrollToTop',null,{root: true});

            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        })
        .catch(error => {

            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });

    },
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

<template>
  <div>
    <v-row 
      no-gutters
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        style="border-bottom:1px solid #000000;"
      />
      <v-col
        v-for="(option,i) in part.options"
        :key="i"
        class="pa-3 text-center white--text black"
      >
        {{ option.label }}
      </v-col>
    </v-row>

    <v-row 
      no-gutters 
      v-for="(question,i) in part.questions" 
      :key="i"
      style="border-left:1px solid #000000;"
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        class="pa-3 black--text"
        style="border-bottom:1px solid #000000;border-right:solid 1px #000000;"
      >
        {{ question.label }}
      </v-col>
      <v-col
        v-for="(option,j) in part.options"
        :key="j"
        class="text-center pa-3 grille-col"
        style="border-bottom:1px solid #000000;border-right:solid 1px #000000;"
      >
        <v-radio-group 
          v-model="answers[i]"
          :rules="rules"
          :disabled="disabled"
        >
          <v-radio
            :value="j + 1"
            class="mx-auto"
          />
        </v-radio-group>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    props: {
        part: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        answers: []
    }),
    methods:{
        prepareAnswers(){
            this.part.questions.forEach (() => {
                this.answers.push(null);
            });
        }
    },
    computed:{
        rules : function(){
            if (this.part.required == true) {
                return [v => !!v || ''];
            } else {
                return [];
            }
        },
    },
    watch:{
        answers(){
            this.$emit('input', this.answers);
        }
    },
    beforeMount(){
        this.prepareAnswers();
    }
}
</script>


<template>
  <div>
    <v-checkbox 
      :disabled="disabled"
      v-for="(option,j) in part.options"
      :key="j"
      :label="option.label"
      :value="j + 1"
      v-model="value"
      :multiple="part.multiple"
      :rules="rules"
    />
    <v-checkbox 
      :disabled="disabled"
      v-if="part.open"
      :key="part.options.length"
      :label="part.openLabel"
      value="autre"
      v-model="value"
      :multiple="part.multiple"
      :rules="rules"
    />
    <Animated 
      enter="fadeIn" 
      leave="fadeOut"
    >
      <v-text-field
        v-if="part.open && value.includes('autre')"
        :label="part.openTextLabel"
        v-model="autre"
        :rules="openRules"
      />
    </Animated>
  </div>
</template>

<script>
export default {
    props: {
        part: {
            type: Object,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        value: [],
        autre: null,
    }),
    computed:{
        openRules : function(){
            if (this.part.required == true && !this.disabled && this.value.includes('autre')) {
                return [v => !!v || 'Ce champ est obligatoire.']
            } else {
                return [];
            }
        },
        rules : function(){
            if (this.part.required == true && !this.disabled){
                if (this.part.multiple == true){
                    if (this.part.minSelection > 1) {
                        return [v => v.length >= this.part.minSelection || 'Vous devez sélectionner au moins ' + this.part.minSelection + ' réponse(s).'];
                    } else {
                        return [v => v.length > 0 || 'Vous devez sélectionner une réponse.'];
                    }
                } else {
                    return [this.value == null || 'Vous devez sélectionner une réponse.'];
                }
            } else {
                return [];
            }
        },
        answers(){
            if(this.part.open){
                return (this.value.includes('autre'))?[this.value,this.autre]:[this.value,null];
            } else {
                return this.value;
            }
        },
    },
    watch:{
        answers(){
            this.$emit('input', this.answers);
        },
        value(){
            if (!this.value.includes('autre')){
                this.autre = null;
            }
        },
        disabled(){
            if (this.disabled) {
                this.value = [];
                this.autre = null;
            }
        } 
    },
}
</script>
import Vue      from 'vue'
import App      from './App.vue'
import store    from './store'
import router   from './router'

// Vuetify
import vuetify  from './plugins/vuetify'

// Vuetify dialog confirmation
import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, { vuetify })

// Vue animation
import VueAnimated from '@codekraft-studio/vue-animated'
Vue.use(VueAnimated)

// Vue2Editor
// import Vue2Editor from "vue2-editor";
// Vue.use(Vue2Editor);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  beforeCreate() { 
    this.$store.dispatch('init');
    this.$store.dispatch('auth/getUser');
  },
  mounted(){
    // Prevent browser back
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
  },
}).$mount('#app')

import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {

    // Get list of available DPC
    async getListe(){
        await Csrf.getCookie();
        return Api.get(`/dashboard/dpc/disponibles`);
    },

    // Register user to a new DPC
    async register(id){
        await Csrf.getCookie();
        return Api.post(`/dashboard/dpc/disponibles/register`,{'id':id});
    },

}
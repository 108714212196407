import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {
  
  async getParams(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/params`,{'id':id});
  },

  async updateSessionLog(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/updateSessionLog`,{'id':id});
  },
  
  async getStepData(id,step){
    await Csrf.getCookie();
    return Api.post(`/dpc/stepdata`,{'id':id,'step':step});
  },

  async startDpc(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/start`,{'id':id});
  },

  async saveEpp(form){
    await Csrf.getCookie();
    return Api.post(`/dpc/saveEpp`,form);
  },

  async saveEppComments(form){
    await Csrf.getCookie();
    return Api.post(`/dpc/saveEppComments`,form);
  },

  async gotoEppStats(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/gotoEppStats`,{'id':id});
  },

  async gotoEppComments(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/gotoEppComments`,{'id':id});
  },

  // async endEpp(id){
  //   await Csrf.getCookie();
  //   return Api.post(`/dpc/endEpp`,{'id':id});
  // },

  async saveBilan(form){
    await Csrf.getCookie();
    return Api.post(`/dpc/saveBilan`,form);
  },

  async getResults(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/results`,{'id':id});
  },

  async saveResults(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/saveResults`,{'id':id});
  },

  async resetResults(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/resetResults`,{'id':id});
  },

  async getRecap(id){
    await Csrf.getCookie();
    return Api.post(`/dpc/recap`,{'id':id});
  },

}
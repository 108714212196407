import Api      from "@/apis/Api.js";
import Csrf     from "@/apis/Csrf"

export default {

  // Get list of specialites and list of roles
  async getParams(){
    await Csrf.getCookie();
    return Api.post(`/register/params`);
  },
    
  // Register user
  async register(form){
    await Csrf.getCookie();
    return Api.post(`/user/register`,form);
  },
  
}
<template>
  <v-card 
    outlined 
    class="pa-md-4 pa-lg-5"
  >
    <v-card-title 
      class="d-block primary white--text rounded"
    >
      <h3>Audit clinique {{ typeEpp }} - Bilan</h3>
    </v-card-title>
    
    <v-card-text 
      style="font-size:inherit;" 
      class="pt-10"
    >
      <v-form 
        ref="form" 
        class="grille"
      >
        <Animated 
          enter="fadeIn" 
          leave="fadeOut" 
          :duration="{ enter: 1000, leave: 0 }"
        >          
          <div 
            class="mt-10"
          >
            <p 
              v-if="typeEpp == 1"
            >
              Suite à l’analyse des différents dossiers patients, quel premier bilan tirez-vous de votre pratique professionnelle ?
            </p>
            <p 
              v-if="typeEpp == 2"
            >
              Quelle(s) action(s) d’amélioration avez-vous mis en place ?
            </p>
            <v-textarea
              v-model="formCommentaires.bilan"
              background-color="blue-grey lighten-5"
              class="rounded"
              outlined
              auto-grow
              :rules="commentsRules"
            />
            <p 
              class="mt-5"
              v-if="typeEpp == 1"
            >
              Quelle(s) action(s) d’amélioration souhaitez-vous mettre en place ?
            </p>
            <p 
              class="mt-5"
              v-if="typeEpp == 2"
            >
              Ce programme vous a-t-il effectivement permis d’améliorer un aspect de votre pratique professionnelle ? <br>
              Quelle suite pensez-vous donner à ce travail ?
            </p>
            <v-textarea
              v-model="formCommentaires.commentaires"
              background-color="blue-grey lighten-5"
              class="rounded"
              outlined
              auto-grow
              :rules="commentsRules"
            />
          </div>
        </Animated>
      </v-form>
    </v-card-text>
    <v-card-actions 
      class="justify-center py-10"
    >
      <Animated 
        enter="fadeIn" 
        leave="fadeOut" 
        :duration="{ enter: 1000, leave: 0 }"
      >
        <v-btn 
          depressed 
          @click="enregistrerCommentaires" 
          large 
          class="my-2 mx-2 px-5" 
          color="success"
        >
          Enregistrer &amp; continuer 
          <v-icon 
            class="ml-2"
          >
            mdi-content-save-move-outline
          </v-icon>
        </v-btn>
      </Animated>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    step:{
      type: Object,
      required : true,
    },
  },
  data: () => ({
    formCommentaires:{
      'bilan': null,
      'commentaires' : null,
    },
  }),
  computed:{
    typeEpp(){
      return this.step.epp;
    },
    commentsRules : function(){
      // if (this.typeEpp == 2){
          return [v => !!v || 'Ce champ est obligatoire.']
      // } else {
      //     return [];
      // }
    }
  },
  methods: {
    enregistrerCommentaires(){
      if (this.$refs.form.validate()) {
        this.$store.dispatch('dpc/saveEppComments',{
          'type'          : this.typeEpp,
          'bilan'         : this.formCommentaires.bilan,
          'commentaires'  : this.formCommentaires.commentaires,
        });
      } else {
        this.$store.dispatch('overlay/setMessages',[{type:'info',message:'Merci de répondre à toutes les questions obligatoires.'}]);
      }
    },
  },
}
</script>

<style>
/* .grille .v-messages{
  min-height: unset;
}
.grille .v-input--selection-controls{
  padding:0;
  margin: 0;
}
.grille .v-input--radio-group{
  padding:0;
  margin: 0;
}
.grille .v-input__slot{
  padding:0;
  margin: 0;
}
.theme--light.v-card > .v-card__text {
  color: #000000;
}
.theme--light.v-label{
  color:#000000;
}
.theme--light.v-icon{
  color:#000000;
}


table.audit {
  border: 1px solid #000000;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
}

table.audit tr {
  border: 1px solid #000000;
  padding: 15px;
}

table.audit th,
table.audit td {
  padding: 15px;
  text-align: center;
  border: 1px solid #000000;
}

table.audit th {
  font-size: 0.8rem;
  text-transform: uppercase;
}

table.audit tr td.patientCount{
  width:1%;
  white-space:nowrap;
}


@media screen and (max-width: 960px) {
  table.audit {
    border: 0;
  }

  table.audit thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table.audit tr {
    display: block;
    margin-bottom: 30px;
  }
  
  table.audit td {
    border: none;
    border-bottom: 1px solid #000000;
    display: block;
    text-align: right;
  }

  table.audit tr td.patientCount{
    width:auto;
  }
  
  table.audit td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    display: block;
    margin-bottom: 15px;
  }
  
  table.audit td:last-child {
    border-bottom: 0;
  }
} */
</style>
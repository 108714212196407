const state = {
    enabled: false,
    messages: [],
};

const getters = {
    isEnabled: (state) => state.enabled,
    getMessages: (state) => state.messages
};

const actions = {
    init({commit}){
        commit('DISABLE');
        commit('CLEAR_MESSAGES');
    },
    enable({commit}){
        commit('ENABLE');
    },
    disable({commit}){
        commit('DISABLE');
    },
    clearMessages({commit}){
        commit('CLEAR_MESSAGES');
    },
    setMessages({commit},messages){
        commit('SET_MESSAGES',messages);
    }
};

const mutations = {
    DISABLE(state) {
        state.enabled = false;
    },
    ENABLE(state){
        state.enabled = true;
    },
    CLEAR_MESSAGES(state) {
        state.messages = [];
    },
    SET_MESSAGES(state,messages){
        state.messages = messages;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

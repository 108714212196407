import api from './apis/Api'

const state = {
    sessionId: null,
    sessionLogId: null,
    documentTitle: '',
    titre: '',
    steps: [],
    completed: false,
    stepper: 1,
    stepData: {},
    results : null,
    recap : null,
    loading: true
};
const getters = {
    getSessionId: (state) => state.sessionId,
    getSessionLogId: (state) => state.sessionLogId,
    getDocumentTitle: (state) => state.documentTitle,
    getTitre: (state) => state.titre,
    getSteps: (state) => state.steps,
    getStepper: (state) => state.stepper,
    getStepData: (state) => state.stepData,
    getResults: (state) => state.results,
    getRecap: (state) => state.recap,
    isLoading: (state) => state.loading,
    isCompleted: (state) => state.completed,
};
const actions = {

    init({commit}){
        commit('SET_SESSIONID', null);
        commit('SET_SESSIONLOGID', null);
        commit('SET_TITRE', 'Chargement en cours...');
        commit('SET_DOCUMENTTITLE', 'Chargement en cours...');
        commit('SET_STEPS', []);
        commit('SET_COMPLETED', false);
        commit('SET_STEPPER', 1);
        commit('SET_LOADING', true);
        commit('SET_STEPDATA', {});
        commit('SET_RESULTS', null);
        commit('SET_RECAP', null);
    },

    // Get DPC Session Params
    getParams({commit,dispatch,state},sessionId){

        dispatch('overlay/enable',null,{root:true});

        api.getParams(sessionId)
        .then( response => {

            dispatch('toolbar/enable', {titre: response.data.titre, backRouteName: 'Dashboard', backRouteTooltip: 'Retour au tableau de bord'}, { root: true });
            dispatch('overlay/disable',null,{root:true});

            if (response.data){
                commit('SET_SESSIONID', sessionId);
                commit('SET_SESSIONLOGID', response.data.sessionLogId);
                commit('SET_DOCUMENTTITLE', response.data.titre);
                commit('SET_TITRE', response.data.titre);
                commit('SET_COMPLETED',response.data.completed);
                commit('SET_STEPS', response.data.steps);
                commit('SET_STEPPER', response.data.stepper);

                if (!response.data.completed){
                    dispatch('getStepData',state.stepper);
                }

            }

            dispatch('overlay/disable',null,{root:true});
            commit('SET_LOADING', false);

        })
        .catch(error => {

            dispatch('toolbar/enable', {titre: 'Erreur...', backRouteName: 'Dashboard', backRouteTooltip: 'Retour au tableau de bord'}, { root: true });
            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });

    },

    // Get current step contents
    getStepData({commit,dispatch,state},step){

        dispatch('overlay/enable',null,{root:true});

        api.getStepData(state.sessionId,step)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data){
                commit('SET_STEPPER', step);
                commit('SET_STEPDATA', response.data);
                dispatch('scrollToTop',null,{root: true});
            }

        })
        .catch(error => {

            commit('SET_STEPDATA', null);
            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });

    },

    // Commencer le programme DPC
    startDpc({dispatch,state,commit}){
        dispatch('overlay/enable',null,{root:true});

        api.startDpc(state.sessionId)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data){
                commit('SET_STEPPER', response.data.stepper);
                commit('SET_STEPDATA', null);
                
                dispatch('getStepData',response.data.stepper);
                dispatch('scrollToTop',null,{root: true});
                
            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        })
        .catch(error => {

            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });
        
    },

    // Enregistrer Epp
    saveEpp({dispatch,state,commit},form){
        dispatch('overlay/enable',null,{root:true});

        form.id = state.sessionId;

        api.saveEpp(form)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data.count > 0){

                let newStepData = state.stepData;
                newStepData.count = response.data.count;
                commit('SET_STEPDATA', newStepData);

                dispatch('scrollToTop',null,{root: true});
                dispatch('overlay/setMessages',[{type:'success',message:'Données du dossier ' + response.data.count + ' enregistrées avec succès.'}],{root: true});
                
            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        })
        .catch(error => {

            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });
        
    },

    // Enregistrer Epp Commentaires
    saveEppComments({dispatch,state,commit},form){
        dispatch('overlay/enable',null,{root:true});

        form.id = state.sessionId;

        api.saveEppComments(form)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data){
                commit('SET_STEPPER', response.data.stepper);
                commit('SET_STEPDATA', null);
                
                dispatch('getStepData',response.data.stepper);
                dispatch('scrollToTop',null,{root: true});
                
            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        })
        .catch(error => {

            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });
        
    },

    // Proceed to Epp Stats page
    gotoEppStats({dispatch,state,commit}){
        dispatch('overlay/enable',null,{root:true});
  
        api.gotoEppStats(state.sessionId)
        .then( response => {
  
            dispatch('overlay/disable',null,{root:true});
  
            if (response.data){
                commit('SET_STEPPER', response.data.stepper);
                commit('SET_STEPDATA', null);
                
                dispatch('getStepData',response.data.stepper);
                dispatch('scrollToTop',null,{root: true});
                
            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }
  
        })
        .catch(error => {
  
            dispatch('overlay/disable',null,{root:true});
  
            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                  //   dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }
  
        });
        
    },
  
    // Proceed to Epp Comments page
    gotoEppComments({dispatch,state,commit}){
        dispatch('overlay/enable',null,{root:true});
  
        api.gotoEppComments(state.sessionId)
        .then( response => {
  
            dispatch('overlay/disable',null,{root:true});
  
            if (response.data){
                commit('SET_STEPPER', response.data.stepper);
                commit('SET_STEPDATA', null);
                
                dispatch('getStepData',response.data.stepper);
                dispatch('scrollToTop',null,{root: true});
                
            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }
  
        })
        .catch(error => {
  
            dispatch('overlay/disable',null,{root:true});
  
            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                  //   dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }
  
        });
        
    },
  
    // End Epp Step
    // endEpp({dispatch,state,commit}){
    //     dispatch('overlay/enable',null,{root:true});
  
    //     api.endEpp(state.sessionId)
    //     .then( response => {
  
    //         dispatch('overlay/disable',null,{root:true});
  
    //         if (response.data){
    //             commit('SET_STEPPER', response.data.stepper);
    //             commit('SET_STEPDATA', null);
                
    //             dispatch('getStepData',response.data.stepper);
    //             dispatch('scrollToTop',null,{root: true});
                
    //         } else {
    //             dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
    //         }
  
    //     })
    //     .catch(error => {
  
    //         dispatch('overlay/disable',null,{root:true});
  
    //         if (error.response) {
    //             // client received an error response (5xx, 4xx)
    //             if (error.response.status === 401){
    //                 dispatch('auth/logoutUser',null,{root: true});
    //               //   dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
    //             } else {
    //                 dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
    //             }
    //         } else if (error.request) {
    //             // client never received a response, or request never left
    //             dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
    //         } else {
    //             // anything else
    //             dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
    //         }
  
    //     });
        
    // },
  
    // Accept results & continue
    saveResults({dispatch,state,commit}){
      dispatch('overlay/enable',null,{root:true});

      api.saveResults(state.sessionId)
      .then( response => {

          dispatch('overlay/disable',null,{root:true});

          if (response.data){
              commit('SET_STEPPER', response.data.stepper);
              commit('SET_STEPDATA', null);
              
              dispatch('getStepData',response.data.stepper);
              dispatch('scrollToTop',null,{root: true});
              
          } else {
              dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
          }

      })
      .catch(error => {

          dispatch('overlay/disable',null,{root:true});

          if (error.response) {
              // client received an error response (5xx, 4xx)
              if (error.response.status === 401){
                  dispatch('auth/logoutUser',null,{root: true});
                //   dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
              } else {
                  dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
              }
          } else if (error.request) {
              // client never received a response, or request never left
              dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
          } else {
              // anything else
              dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
          }

      });
      
    },
    
    // reset results & restart dpc
    resetResults({dispatch,state,commit}){
      dispatch('overlay/enable',null,{root:true});

      api.resetResults(state.sessionId)
      .then( response => {

          dispatch('overlay/disable',null,{root:true});

          if (response.data){
              commit('SET_STEPPER', response.data.stepper);
              commit('SET_STEPDATA', null);
              
              dispatch('getStepData',response.data.stepper);
              dispatch('scrollToTop',null,{root: true});
              
          } else {
              dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
          }

      })
      .catch(error => {

          dispatch('overlay/disable',null,{root:true});

          if (error.response) {
              // client received an error response (5xx, 4xx)
              if (error.response.status === 401){
                  dispatch('auth/logoutUser',null,{root: true});
                //   dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
              } else {
                  dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
              }
          } else if (error.request) {
              // client never received a response, or request never left
              dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
          } else {
              // anything else
              dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
          }

      });
      
    },
    
    // Enregistrer Bilan de la formation
    saveBilan({dispatch,state,commit},form){
        dispatch('overlay/enable',null,{root:true});

        form.id = state.sessionId;

        api.saveBilan(form)
        .then( response => {

            dispatch('overlay/disable',null,{root:true});

            if (response.data.status == 'complete'){
                commit('SET_COMPLETED', true);
                dispatch('overlay/setMessages',[{type:'success',message:'BRAVO !<br> Vous avez complété ce programme DPC.'}],{root: true});
            } else {
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        })
        .catch(error => {

            dispatch('overlay/disable',null,{root:true});

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });
        
    },

    // Get results
    getResults({commit,dispatch,state}){

        // commit('SET_STEPDATA', null);
        dispatch('scrollToTop',null,{root: true});

        api.getResults(state.sessionId)
        .then( response => {

            if (response.data){
                commit('SET_RESULTS', response.data);
            }

        })
        .catch(error => {

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });

    },   
 
    // Get Recap
    getRecap({commit,dispatch,state}){

        // commit('SET_STEPDATA', null);
        dispatch('scrollToTop',null,{root: true});

        api.getRecap(state.sessionId)
        .then( response => {

            if (response.data){
                commit('SET_RECAP', response.data);
            }

        })
        .catch(error => {

            if (error.response) {
                // client received an error response (5xx, 4xx)
                if (error.response.status === 401){
                    dispatch('auth/logoutUser',null,{root: true});
                    // dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}],{root: true});
                } else {
                    dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
                }
            } else if (error.request) {
                // client never received a response, or request never left
                dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}],{root: true});
            } else {
                // anything else
                dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}],{root: true});
            }

        });

    },   
    
};
const mutations = {
    SET_SESSIONID(state,value) {
        state.sessionId = value;
    },
    SET_SESSIONLOGID(state,value) {
        state.sessionLogId = value;
    },
    SET_DOCUMENTTITLE(state,documentTitle) {
        state.documentTitle = documentTitle;
    },
    SET_TITRE(state,titre) {
        state.titre = titre;
    },
    SET_STEPS(state,steps) {
        state.steps = steps;
    },
    SET_COMPLETED(state,value) {
        state.completed = value;
    },
    SET_STEPPER(state,stepper) {
        state.stepper = stepper;
    },
    SET_STEPDATA(state,stepData) {
        state.stepData = stepData;
    },
    SET_RESULTS(state,results) {
        state.results = results;
    },
    SET_RECAP(state,recap) {
        state.recap = recap;
    },
    SET_LOADING(state,value) {
        state.loading = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

<template>
  <v-card 
    outlined 
    class="pa-md-4 pa-lg-5"
  >
    <v-card-title 
      class="d-block primary white--text rounded"
    >
      <h3>Récapitulatif de votre programme DPC</h3>
    </v-card-title>

    <template v-if="recap">
      <v-card-text 
        style="font-size:inherit;" 
        class="pt-10"
        v-html="parseHtml(recap.intro)"
      />

      <v-card-subtitle class="d-block grey darken-2 white--text rounded px-4 py-2 mt-4">
        <h3>Récapitulatif parcours</h3>
      </v-card-subtitle>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Etape
              </th>
              <th class="text-center">
                Début
              </th>
              <th class="text-center">
                Fin
              </th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="etape in recap.etapes" 
              :key="etape.id"
            >
              <td class="text-left">
                {{ etape.titre }}
              </td>
              <td class="text-center">
                {{ etape.created_at }}
              </td>
              <td class="text-center">
                {{ etape.updated_at }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
  

      <v-card-subtitle class="d-block grey darken-2 white--text rounded px-4 py-2 mt-8">
        <h3>Synthèse de vos réponses pour chaque section</h3>
      </v-card-subtitle>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Section
              </th>
              <th class="text-center">
                No. de questions
              </th>
              <th class="text-center">
                Bonnes réponses
              </th>
              <th class="text-center">
                % Bonnes réponses
              </th>
              <th class="text-center">
                Degré de certitude
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="dpc in recap.dpc">
              <tr 
                v-if="dpc.maxscore > 0" 
                :key="dpc.id"
              >
                <td class="text-left">
                  {{ dpc.titre }}
                </td>
                <td class="text-center">
                  {{ dpc.maxscore }}
                </td>
                <td class="text-center">
                  {{ dpc.score }}
                </td>
                <td class="text-center">
                  {{ Math.round(dpc.score / dpc.maxscore * 100) }}%
                </td>
                <td class="text-center">
                  {{ dpc.evaluation }}%
                </td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <th class="text-left">
                {{ tableData.footer[0] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[1] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[2] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[3] }}
              </th>
              <th class="text-center">
                {{ tableData.footer[4] }}
              </th>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>

      <v-card-text 
        style="font-size:inherit;" 
        class="pt-10"
        v-html="parseHtml(recap.refs)"
      />

      <div class="mt-4 text-center">
        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="success"
          depressed
          @click="retour"
        >
          <v-icon class="mr-1">
            mdi-arrow-left-bold-circle-outline
          </v-icon>
          Revenir à l'accueil 
        </v-btn>
        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="info"
          depressed
          @click="saveTable"
        >
          <v-icon class="mr-1">
            mdi-file-pdf-outline
          </v-icon>
          Synthèse de vos réponses pour chaque section 
        </v-btn>
        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="info"
          depressed
          :href="downloadDocsUrl"
        >
          <v-icon class="mr-1">
            mdi-file-pdf-outline
          </v-icon>
          Tous les « Essentiels » 
        </v-btn>
      </div>
    </template>

    <v-card-text 
      v-else 
      class="text-center mt-4"
    >
      <v-icon 
        color="primary" 
        large
      >
        mdi-loading mdi-spin
      </v-icon>
    </v-card-text>
  </v-card>
</template>

<script>
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import parseHtmlMixin  from '@/mixins/parseHtml'
import sanitizeFilename from '@/mixins/sanitizeFilename'

export default {
  computed:{
    recap(){
      return this.$store.getters['dpc/getRecap'];
    },
    tableData(){

      let table = {};
      table.header = ['Section','No. de questions','Bonnes réponses','% Bonnes réponses','Degré de certitude'];

      let data = [];
      let totalQuestions = 0;
      let totalScore = 0;
      let counter = 0;
      let totalEvaluation = 0;
      
      this.recap.dpc.forEach(function(element) { 
        if (element.maxscore > 0){
          counter++;
          totalQuestions = totalQuestions + element.maxscore;
          totalScore = totalScore + element.score;
          totalEvaluation = totalEvaluation + element.evaluation;
          data.push([
            element.titre,
            element.maxscore,
            element.score,
            Math.round(element.score / element.maxscore * 100) + '%',
            element.evaluation + '%',
          ]);
        }
      });

      table.footer = [
        '',
        totalQuestions,
        totalScore,
        Math.round(totalScore / totalQuestions * 100) + '%',
        Math.round(totalEvaluation / counter) + '%'
      ];

      table.data = data;

      return table;
    },
    tableDataRecap(){

      let table = {};
      table.header = ['Etape','Début','Fin'];

      let data = [];
      
      this.recap.etapes.forEach(function(element) { 
        data.push([
          element.titre,
          element.created_at,
          element.updated_at,
        ]);
      });

      table.data = data;

      return table;
    },
    downloadDocsUrl(){
      return process.env.VUE_APP_URL + '/dpc/' + this.$store.getters['dpc/getSessionId'] + '/downloadAll';
    },
  },
  methods:{
    saveTable() {

      const doc = new jsPDF('l', 'pt', 'A4')

      doc.setFontSize('16');
      doc.text('DPC : ' + this.$store.getters['dpc/getTitre'], 40, 50);

      doc.setFontSize('12');
      doc.text(this.$store.getters['auth/getUsername'], 40, 70);

      doc.autoTable({
        head: [this.tableDataRecap.header],
        body: this.tableDataRecap.data,
        margin: { top: 85 },
        styles: {
          halign: 'center',
        },
        columnStyles: { 
          0: { halign: 'left' },
          1: { halign: 'center' },
          2: { halign: 'center' },
        },
      })

      doc.autoTable({
        head: [this.tableData.header],
        foot: [this.tableData.footer],
        body: this.tableData.data,
        styles: {
          halign: 'center',
        },
        columnStyles: { 
          0: { halign: 'left' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          3: { halign: 'center' },
          4: { halign: 'center' },
          5: { halign: 'center' },
          6: { halign: 'center' },
        },
      })

      let filename = this.sanitizeFilename('synthese-reponses-' + this.$store.getters['dpc/getTitre']);
      doc.save(filename);

    },
    retour(){
        this.$store.dispatch('toolbar/disable');
        this.$router.push({ name: 'Dashboard' });
    },
  },
  mounted(){
    this.$store.dispatch('dpc/getRecap');
  },
  mixins: [
    parseHtmlMixin,
    sanitizeFilename,
  ],
}
</script>

<template>
  <v-container
    class="fill-height bgcolor"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="5"
        lg="4"
      >
        <v-card
          flat
          class="my-10"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Réinitialisation du mot de passe</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="px-8">
            <v-form v-model="isValid">
              <v-text-field
                label="Email"
                name="email"
                v-model="form.email"
                prepend-icon="mdi-at"
                type="text"
                required
                :rules="emailRules"
                :error-messages="errors.email"
                @input="clearFormErrors"
              />
              <v-text-field
                id="password"
                label="Nouveau mot de passe"
                name="password"
                v-model="form.password"
                prepend-icon="mdi-lock"
                type="password"
                required
                :rules="passwordRules"
                :error-messages="errors.password"
                @input="clearFormErrors"
              />
              <v-text-field
                id="password_confirmation"
                label="Confirmez le nouveau mot de passe"
                name="password_confirmation"
                v-model="form.password_confirmation"
                prepend-icon="mdi-lock"
                type="password"
                required
                :rules="passwordConfirmRules.concat(passwordConfirmationRule)"
                :error-messages="errors.password_confirmation"
                @input="clearFormErrors"
              /> 
            </v-form>

            <span 
              class="error--text mr-2" 
              v-if="errors.login"
            >
              {{ errors.login[0] }}
            </span>
          </v-card-text>


          <v-card-text 
            class="px-8"
          >
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="recaptcha.key" 
              :load-recaptcha-script="true" 
              language="fr"
              @verify="onVerify"
              @expired="onExpired"
            />

            <span 
              class="error--text mr-2" 
              v-if="errors['g-recaptcha-response']"
            >
              Erreur au niveau du Captcha!
            </span>

            <v-btn 
              icon 
              small
              @click="resetRecaptcha" 
            >
              <v-icon dark>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-actions class="px-8 pb-8">
            <v-btn 
              @click.prevent="resetPassword" 
              color="primary" 
              :disabled="!isValid || !recaptcha.valid"
              depressed
            >
              Modifier
            </v-btn>
            <v-spacer />
            <v-btn 
              @click.prevent="connexion" 
              text
            >
              Connexion
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default{
  components: { VueRecaptcha },
  data: () => ({
    errors: [],
    recaptcha: {
      key: process.env.VUE_APP_RECAPTCHA_KEY,
      valid: false,
    },
    form: {
      email: null,
      password: null,
      password_confirmation: null,
      recaptcha:null,
      token: null,
    },
    emailRules: [
      v => !!v || 'Veuillez entrer votre email.'
    ],
    passwordRules: [
      v => !!v || 'Veuillez renseigner un mot de passe.',
      v => (v && v.length >= 8) || 'Votre mot de passe doit contenir au minimum 8 caractères.',
    ],
    passwordConfirmRules: [
      v => !!v || 'Veuillez confirmer votre mot de passe.'
    ],
    isValid: true,
  }),
  methods: {

    connexion(){
      this.$router.push({ name: 'Connexion' });
    },

    resetPassword(){
      this.$store.dispatch('auth/resetPassword',this.form);
    },

    // reCaptcha Methods
    onVerify: function (response) {
      this.recaptcha.valid = true;
      this.form.recaptcha = response;
      this.$store.dispatch['auth/clearErrors'];
    },
    onExpired: function () {
      this.recaptcha.valid = false;
      this.form.recaptcha = null;
      this.$store.dispatch['auth/clearErrors'];
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset()
      this.recaptcha.valid = false;
      this.form.recaptcha = null;
      this.$store.dispatch['auth/clearErrors'];
    },
    clearFormErrors(){
      this.errors = [];
    },

  },
  computed:{

    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation || "Les mots de passe doivent correspondre";
    },
    authErrors() {
      return this.$store.getters['auth/getErrors'];
    }

  },
  watch:{
    authErrors(){
      this.errors = this.authErrors;
      this.resetRecaptcha();
    }
  },
  beforeCreate(){
      this.$store.dispatch('auth/clearErrors');
  },
  beforeMount(){
    document.title = 'Réinitialisation du mot de passe';
    this.form.token = this.$route.params.token;
  },
}
</script>
<style scoped>
.bgcolor{
  background: #e0e0e0;
}
</style>
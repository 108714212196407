<template>
  <div />
</template>

<script>
export default{
  beforeMount(){
    document.title = 'Adresse email vérifiée';
    this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Votre adresse email a été validée.' }]);
    this.$router.push({ name: 'Connexion' });
  },
}
</script>
<style scoped>
.bgcolor{
  background: #e0e0e0;
}
</style>
<template>
  <v-card
    outlined 
    class="pa-md-4 pa-lg-5"
  >
    <v-card-title 
      class="d-block primary white--text rounded"
    >
      <h3>Questionnaire de satisfaction de la formation</h3>
    </v-card-title>
    <v-card-text 
      style="font-size:inherit;" 
      class="pt-10"
    >
      <p>
        Vous venez de terminer votre programme DPC non présentiel, conçu par les formateurs de l‘ARMP. 
        Afin d’en améliorer la qualité et vous apporter la satisfaction attendue, votre avis nous est précieux.
      </p>
      <p>
        Nous vous remercions, par avance du temps accordé pour répondre à ce questionnaire.
      </p>
      <v-form ref="form">
        <h4 
          class="my-12"
        >
          1. Est-ce que ce programme DPC a répondu à vos attentes ?
        </h4>
        <v-slider
          v-model="form.q1"
          thumb-size="32"
          min="1"
          max="10"
          thumb-label="always"
          :tick-labels="tickLabels"
        >
          <template 
            v-slot:thumb-label="{ value }"
          >
            {{ satisfactionEmojis[value - 1] }}
          </template>
          <template 
            v-slot:prepend
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Insuffisant</span>
            </v-chip>
          </template>
          <template 
            v-slot:append
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Très bien</span>
            </v-chip>
          </template>    
        </v-slider>
        <h4 
          class="my-12"
        >
          2. Que pensez-vous de l’ergonomie de la plateforme de saisie ?
        </h4>
        <v-slider
          v-model="form.q2"
          thumb-size="32"
          min="1"
          max="10"
          thumb-label="always"
          :tick-labels="tickLabels"
        >
          <template 
            v-slot:thumb-label="{ value }"
          >
            {{ satisfactionEmojis[value - 1] }}
          </template>
          <template 
            v-slot:prepend
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Insuffisant</span>
            </v-chip>
          </template>
          <template 
            v-slot:append
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Très bien</span>
            </v-chip>
          </template>    
        </v-slider>
        <h4 
          class="my-12"
        >
          3. Avez-vous été satisfait de l’apport cognitif de ce programme DPC ?
        </h4>
        <v-slider
          v-model="form.q3"
          thumb-size="32"
          min="1"
          max="10"
          thumb-label="always"
          :tick-labels="tickLabels"
        >
          <template 
            v-slot:thumb-label="{ value }"
          >
            {{ satisfactionEmojis[value - 1] }}
          </template>
          <template 
            v-slot:prepend
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Insuffisant</span>
            </v-chip>
          </template>
          <template 
            v-slot:append
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Très bien</span>
            </v-chip>
          </template>    
        </v-slider>
        <h4 
          class="my-12"
        >
          4. Pensez-vous pouvoir en tirer des apports concrets à mettre en œuvre ?
        </h4>

        <v-slider
          v-model="form.q4"
          thumb-size="32"
          min="1"
          max="10"
          thumb-label="always"
          :tick-labels="tickLabels"
        >
          <template 
            v-slot:thumb-label="{ value }"
          >
            {{ satisfactionEmojis[value - 1] }}
          </template>
          <template 
            v-slot:prepend
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Non</span>
            </v-chip>
          </template>
          <template 
            v-slot:append
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Oui</span>
            </v-chip>
          </template>    
        </v-slider>
        <h4 
          class="my-12"
        >
          5. Quelle note globale donnez-vous à ce programme DPC ?
        </h4>
        <v-slider
          v-model="form.q5"
          thumb-size="32"
          min="1"
          max="10"
          thumb-label="always"
          :tick-labels="tickLabels"
        >
          <template 
            v-slot:thumb-label="{ value }"
          >
            {{ satisfactionEmojis[value - 1] }}
          </template>
          <template 
            v-slot:prepend
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Insuffisant</span>
            </v-chip>
          </template>
          <template 
            v-slot:append
          >
            <v-chip 
              class="primary white--text slider-chips hidden-sm-and-down"
            >
              <span>Très bien</span>
            </v-chip>
          </template>    
        </v-slider>
        <h4 
          class="my-12"
        >
          6. Avez-vous des commentaires et/ou suggestions ?
        </h4>
        <v-textarea
          v-model="form.q6"
          background-color="light-blue accent-1"
          class="rounded"
          outlined
          auto-grow
        />
      </v-form>
    </v-card-text>
    <v-card-actions 
      class="justify-center py-10"
    >
      <Animated 
        enter="fadeIn" 
        leave="fadeOut" 
        :duration="{ enter: 1000, leave: 0 }"
      >
        <v-btn 
          large 
          class="my-4 mx-2 px-5" 
          color="success" 
          @click="saveBilan"
          depressed
        >
          Enregistrer 
          <v-icon 
            class="ml-2"
          >
            mdi-content-save-move-outline
          </v-icon>
        </v-btn>
      </Animated>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    tickLabels: [1,2,3,4,5,6,7,8,9,10],
    satisfactionEmojis: ['😭', '😢', '☹️', '🙁', '😐', '🙂', '😊', '😁', '😄', '😍'],
    form:{
      'q1' : 10,
      'q2' : 10,
      'q3' : 10,
      'q4' : 10,
      'q5' : 10,
      'q6' : null
    }
  }),
  methods: {
    saveBilan(){
      this.$store.dispatch('dpc/saveBilan',this.form);
    },
  },
}
</script>

<style>
.slider-chips span{
  width: 100px;
  text-align: center;
  font-size: 16px;
}
</style>

import store                    from '@/store'

import Connexion                from './Connexion.vue'
import Verified                 from './Verified.vue'
import RequestPasswordResetLink from './RequestPasswordResetLink.vue'
import PasswordReset            from './ResetPassword.vue'

export const AuthRoutes = [

    {
        path: '/connexion',
        name: 'Connexion',
        component: Connexion,
        meta: {
            requiresGuest: true,
            title: 'Connexion'
        }
    },
    {
        path: '/reinitialisation',
        name: 'RequestPasswordResetLink',
        component: RequestPasswordResetLink,
        meta: {
            requiresGuest: true,
            title: 'Réinitialisation du mot de passe'
        }
    },
    {
        path: '/reinitialisation/:token',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
            requiresGuest: true,
            title: 'Réinitialisation du mot de passe'
        }
    },    
    {
        path: '/deconnexion',
        name: 'Logout',
        beforeEnter: () => {
            store.dispatch('auth/logoutUser');
        },
    },
    {
        path: '/verified',
        name: 'Verified',
        component: Verified,
        meta: {
            requiresGuest: true,
            title: 'Connexion'
        }
    },
]
import Vue from 'vue'
import Vuex from 'vuex'
import CreatePersistedState from "vuex-persistedstate"

// Import general modules

//Import other modules Store
import appbar                 from "./components/appbar/store"
import overlay                from "./components/overlay/store"
import toolbar                from "./components/toolbar/store"
import auth                   from "./components/auth/store"
import dashboardencours       from "./components/dashboard/encours/store"
import dpc                    from "./components/dpc/store"
import course                 from "./components/dpc/components/course/store"

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    init({dispatch}) {   

      dispatch('overlay/init');
      dispatch('toolbar/init');
      dispatch('dpc/init');
      dispatch('appbar/init');
      dispatch('auth/init');
      dispatch('course/init');

    },
    scrollToTop(){
      window.scrollTo({
        top: 0,
        left: 0
      });      
    }
  },
  modules: {
    overlay,
    auth,
    appbar,
    toolbar,
    dashboardencours,
    dpc,
    course,
  },
  plugins: [
    CreatePersistedState()
  ]
})

export default store;
export default {
    methods: {
        generateColor(initials) {
            if (initials.length !== 2) {
                return '#bdbdbd';
            }
            const word = initials.toLowerCase();
            const char1 = word.charCodeAt(0) - 97; // Convert first character to a value between 0 and 25
            const char2 = word.charCodeAt(1) - 97; // Convert second character to a value between 0 and 25

            const r = Math.round((char1 / 25) * 255); // Calculate red component
            const g = Math.round((char2 / 25) * 255); // Calculate green component
            const b = Math.round((char1 + char2) / 50 * 255); // Calculate blue component

            return this.rgbToHex(r, g, b);
        },
        rgbToHex(r, g, b) {
            const componentToHex = (c) => {
                const hex = c.toString(16);
                return hex.length === 1 ? '0' + hex : hex;
            };

            return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
        }
    }
};

<template>
  <v-card
    outlined
    class="pa-md-4 pa-lg-5"
  >
    <v-card-title class="d-block primary white--text rounded">
      <h3>Audit Clinique</h3>
    </v-card-title>

    <v-card-text
      style="font-size:inherit;"
      class="pt-10"
    >
      <Animated
        enter="fadeIn"
        leave="fadeOut"
        :duration="{ enter: 1000, leave: 0 }"
      >
        <div
          v-html="parseHtml(step.intro)"
          class="mb-10"
        />
      </Animated>

      <Animated
        enter="fadeIn"
        leave="fadeOut"
        :duration="{ enter: 1000, leave: 0 }"
      >
        <div
          v-if="patientsCount >= patientsCountMin"
          class="text-center mb-10 green lighten-5 pa-10 rounded"
        >
          <p>
            Vous avez déjà enregistré {{ patientsCount }} dossiers patients. Vous pouvez en ajouter jusqu`à {{
              patientsCountMax }} ou passer à l'écran suivant.
          </p>
          <v-btn
            depressed
            @click="gotoStats"
            large
            color="success"
          >
            Passer à l'écran suivant
            <v-icon class="ml-2">
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </div>
      </Animated>

      <Animated
        enter="fadeIn"
        leave="fadeOut"
        :duration="{ enter: 1000, leave: 0 }"
      >
        <v-sheet class="warning white--text d-inline-block px-5 py-2 mb-2 mr-2 rounded">
          <h3>Dossier patient No. {{ patientsCount + 1 }}</h3>
        </v-sheet>
      </Animated>

      <v-form
        ref="form"
        class="grille"
      >
        <Animated
          enter="fadeIn"
          leave="fadeOut"
          :duration="{ enter: 1000, leave: 0 }"
        >
          <div>
            <div
              v-for="(section, sectionIndex) in sections"
              :key="sectionIndex"
            >
              <template v-if="section.type == 'html'">
                <div
                  v-html="parseHtml(section.html)"
                  class="my-10"
                />
              </template>
              <template v-else>
                <h3 class="my-10">
                  {{ section.titre }}
                </h3>
                <table class="audit">
                  <thead>
                    <tr>
                      <th
                        v-for="(question, i) in section.questions"
                        :key="i"
                        scope="col"
                      >
                        {{ question.question }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        v-for="(question, subSectionIndex) in section.questions"
                        :key="subSectionIndex"
                        :data-label="question.question"
                      >
                        <!-- Radio -->
                        <inputRadios
                          :disabled="(question.prerequisite) ? form.answers[sectionIndex][question.prerequisite_index] != question.prerequisite_value : false"
                          v-model="form.answers[sectionIndex][subSectionIndex]"
                          v-if="question.type == 'radio'"
                          :part="question"
                        />
                        <!-- Checkboxgroup -->
                        <inputCheckboxes
                          :disabled="(question.prerequisite) ? form.answers[sectionIndex][question.prerequisite_index] != question.prerequisite_value : false"
                          v-model="form.answers[sectionIndex][subSectionIndex]"
                          v-if="question.type == 'checkboxgroup'"
                          :part="question"
                        />
                        <!-- Textarea -->
                        <inputTextarea
                          :required="question.required"
                          :disabled="(question.prerequisite) ? form.answers[sectionIndex][question.prerequisite_index] != question.prerequisite_value : false"
                          v-model="form.answers[sectionIndex][subSectionIndex]"
                          v-if="question.type == 'textarea'"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </div>
          </div>
        </Animated>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-center py-10">
      <Animated
        enter="fadeIn"
        leave="fadeOut"
        :duration="{ enter: 1000, leave: 0 }"
      >
        <v-btn
          depressed
          v-if="patientsCount < patientsCountMax"
          @click="enregistrerDossier"
          large
          class="my-2 mx-2 px-5"
          color="success"
        >
          Enregistrer
          <v-icon class="ml-2">
            mdi-content-save-move-outline
          </v-icon>
        </v-btn>
      </Animated>
    </v-card-actions>
  </v-card>
</template>

<script>
import parseHtmlMixin from '@/mixins/parseHtml'

import inputRadios from '../inputs/InputRadios'
import inputCheckboxes from '../inputs/InputCheckboxes'
import inputTextarea from '../inputs/InputTextarea'

export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: {
      'answers': [],
    },
    formCommentaires: {
      'bilan': null,
      'commentaires': null,
    },
    defaultAnswers: [],
  }),
  computed: {
    typeEpp() {
      return (this.step.type == 'epp1') ? 1 : 2;
    },
    patientsCount() {
      return this.step.count;
    },
    sections() {
      return this.step.sections;
    },
    patientsCountMin() {
      return this.step.min;
    },
    patientsCountMax() {
      return this.step.max;
    },
  },
  methods: {
    enregistrerDossier() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('dpc/saveEpp', {
          'answers': this.form.answers,
          'type': this.typeEpp,
        });
      } else {
        this.$store.dispatch('overlay/setMessages', [{ type: 'info', message: 'Merci de répondre à toutes les questions obligatoires.' }]);
      }
    },
    prepareDefaultAnswers(sections) {
      let answers = [];
      let index = 0;
      sections.forEach(section => {
        answers.push([]);
        if (section.type != 'html') {
          section.questions.forEach(question => {
            switch (question.type) {
              case 'checkboxgroup':
                if (question.multiple == true) {
                  question.open == true ? answers[index].push([[], null]) : answers[index].push([]);
                } else {
                  answers[index].push(null)
                }
                break;
              case 'radio':
                question.open == true ? answers[index].push([null, null]) : answers[index].push(null);
                break;
              default:
                answers[index].push(null);
            }
          });
        }
        index++;
      });
      this.defaultAnswers = answers;
    },
    prepareAnswers() {
      this.form.answers = this.defaultAnswers.slice();
    },
    gotoStats() {
      this.$store.dispatch('dpc/gotoEppStats');
    },
  },
  beforeMount() {

    // Prepare answers
    this.prepareDefaultAnswers(this.sections);
    this.prepareAnswers();

    if (this.patientsCount >= this.patientsCountMax) {
      this.gotoStats();
    }

  },
  watch: {
    patientsCount() {
      this.prepareAnswers();
      this.$refs.form.reset();
      if (this.patientsCount >= this.patientsCountMax) {
        this.gotoStats();
      }
    }
  },
  components: {
    inputRadios,
    inputCheckboxes,
    inputTextarea,
  },
  mixins: [
    parseHtmlMixin,
  ],
}
</script>

<style>
.grille .v-messages {
  min-height: unset;
}

.grille .v-input--selection-controls {
  padding: 0;
  margin: 0;
}

.grille .v-input--radio-group {
  padding: 0;
  margin: 0;
}

.grille .v-input__slot {
  padding: 0;
  margin: 0;
}

.theme--light.v-card>.v-card__text {
  color: #000000;
}

.theme--light.v-label {
  color: #000000;
}

.theme--light.v-icon {
  color: #000000;
}


table.audit {
  border: 1px solid #000000;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: auto;
}

table.audit tr {
  border: 1px solid #000000;
  padding: 15px;
}

table.audit th,
table.audit td {
  padding: 15px;
  text-align: center;
  border: 1px solid #000000;
}

table.audit th {
  font-size: 0.8rem;
  text-transform: uppercase;
}

table.audit tr td.patientCount {
  width: 1%;
  white-space: nowrap;
}


@media screen and (max-width: 960px) {
  table.audit {
    border: 0;
  }

  table.audit thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.audit tr {
    display: block;
    margin-bottom: 30px;
  }

  table.audit td {
    border: none;
    border-bottom: 1px solid #000000;
    display: block;
    text-align: right;
  }

  table.audit tr td.patientCount {
    width: auto;
  }

  table.audit td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    display: block;
    margin-bottom: 15px;
  }

  table.audit td:last-child {
    border-bottom: 0;
  }
}
</style>
<template>
  <v-container
    class="fill-height bgcolor"
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="11"
        md="11"
        lg="10"
      >
        <v-card
          flat
          class="my-5"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>Formulaire d'inscription</v-toolbar-title>
          </v-toolbar>

          <v-card-text class="px-8">
            <v-form
              ref="form" 
            >
              <p class="mt-5">
                Ce site est réservé exclusivement aux professionnels de santé. Dans le cas où vous ne le seriez pas, nous vous remercions de ne pas vous inscrire.
              </p>
              <p>
                Les champs suivis d’un astérisque (*) doivent être renseignés obligatoirement.
              </p>

              <h2 class="mt-12 mb-5">
                Vos identifiants d’inscription :
              </h2>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Adresse email *"
                    v-model="form.email"
                    required
                    prepend-icon="mdi-at"
                    :rules="emailRules"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Confirmez votre adresse email *"
                    v-model="form.email_confirmation"
                    required
                    prepend-icon="mdi-at"
                    :rules="emailConfirmRules.concat(emailConfirmationRule)"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    id="password"
                    label="Mot de passe"
                    name="password"
                    v-model="form.password"
                    prepend-icon="mdi-lock"
                    type="password"
                    required
                    :rules="passwordRules"
                    :error-messages="errors.password"
                    @input="errors.password = ''"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    id="password_confirmation"
                    label="Confirmez votre mot de passe"
                    name="password_confirmation"
                    v-model="form.password_confirmation"
                    prepend-icon="mdi-lock"
                    type="password"
                    required
                    :rules="passwordConfirmRules.concat(passwordConfirmationRule)"
                    :error-messages="errors.password_confirmation"
                    @input="errors.password_confirmation = ''"
                  /> 
                </v-col>
              </v-row>

              <h2 class="mt-10 mb-5">
                Informations personnelles :
              </h2>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-radio-group
                    v-model="form.civilite"
                    row
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire'
                    ]"
                  >
                    <template v-slot:label>
                      <div>Civilité *</div>
                    </template>                  
                    <v-radio
                      label="Monsieur"
                      :value="1"
                    />
                    <v-radio
                      label="Madame"
                      :value="2"
                    />
                  </v-radio-group>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-radio-group
                    v-model="form.titre"
                    row
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire'
                    ]"
                  >
                    <template v-slot:label>
                      <div>Titre *</div>
                    </template>                  
                    <v-radio
                      label="Docteur"
                      :value="1"
                    />
                    <v-radio
                      label="Professeur"
                      :value="2"
                    />
                  </v-radio-group>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Prénom *"
                    v-model="form.prenom"
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire',
                      v => (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères',
                    ]"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Nom *"
                    v-model="form.nom"
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire',
                      v => (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères',
                    ]"
                    :error-messages="errors.nom"
                    @input="errors.name = ''"
                    required
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-radio-group
                    v-model="form.typeadr"
                    row
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire'
                    ]"
                  >
                    <template v-slot:label>
                      <div>Type adresse *</div>
                    </template>                  
                    <v-radio
                      label="Cabinet"
                      :value="1"
                    />
                    <v-radio
                      label="Hôpital"
                      :value="2"
                    />
                    <v-radio
                      label="Domicile"
                      :value="3"
                    />
                    <v-radio
                      label="Autre"
                      :value="10"
                    />
                  </v-radio-group>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="form.typeadr == 10"
                >
                  <v-text-field
                    label="Autre type d'adresse *"
                    v-model="form.typeadrautre"
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire',
                      v => (v && v.length > 1) || 'Ce champ doit contenir un minimum de 2 caractères',
                    ]"
                    required
                    v-if="form.typeadr == 10"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Adresse L1 *"
                    v-model="form.adressel1"
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire',
                      v => (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères',
                    ]"
                    required
                  />
                  <v-text-field
                    label="Adresse L2"
                    v-model="form.adressel2"
                  />
                  <v-text-field
                    label="Adresse L3"
                    v-model="form.adressel3"
                  />
                  <v-text-field
                    label="Adresse L4"
                    v-model="form.adressel4"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Boite postale"
                    v-model="form.bp"
                  />
                  <v-text-field
                    label="Lieu-dit"
                    v-model="form.lieudit"
                  />
                  <v-text-field
                    label="Code postal *"
                    v-model="form.cp"
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire',
                      v => (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères',
                    ]"
                    required
                  />
                  <v-text-field
                    label="Ville *"
                    v-model="form.ville"
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire',
                      v => (v && v.length >= 2) || 'Ce champ doit contenir un minimum de 2 caractères',
                    ]"
                    required
                  />
                </v-col>
              </v-row>

              <h2 class="mt-10 mb-5">
                Informations professionnelles :
              </h2>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="RPPS"
                    v-model="form.rpps"
                    :rules="[
                      v => (!v || (!!v && v.length >= 11)) || 'Ce champ doit contenir un minimum de 11 chiffres',
                      v => (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres',
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    :items="specialites"
                    v-model="form.specialites"
                    label="Spécialité(s) médicale(s) *"
                    :rules="[
                      v => (v && v.length > 0) || 'Vous devez choisir une ou plusieurs spécialités',
                    ]"
                    required
                    multiple
                    clearable
                    small-chips
                    deletable-chips
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Téléphone *"
                    v-model="form.telephone"
                    :rules="[
                      v => !!v || 'Ce champ est obligatoire',
                      v => (v && v.length >= 8) || 'Ce champ doit contenir un minimum de 8 chiffres',
                      v => Number.isInteger(Number(v)) || 'Ce champ doit contenir uniquement des chiffres',
                    ]"
                    required
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Portable"
                    v-model="form.portable"
                    :rules="[
                      v => (!v || (!!v && v.length >= 8)) || 'Ce champ doit contenir un minimum de 8 chiffres',
                      v => (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres',
                    ]"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Télécopie"
                    v-model="form.telecopie"
                    :rules="[
                      v => (!v || (!!v && v.length >= 8)) || 'Ce champ doit contenir un minimum de 8 chiffres',
                      v => (!v || Number.isInteger(Number(v))) || 'Ce champ doit contenir uniquement des chiffres',
                    ]"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <v-switch
                    v-model="mentions"
                    inset
                    label="J'atteste avoir pris connaissance de la Politique de confidentialité et des Conditions Générales d'Utilisation. *"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-alert
                    color="grey darken-2"
                    dark
                    icon="mdi-exclamation-thick"
                    class="text-caption"
                  >
                    Conformément à la loi n°78-17 du 6 janvier 1978 modifiée et au Règlement (UE) 2016/679 du 27 avril 2016 (RGPD), l'Agence CCC, en qualité de responsable de traitement, est amenée à traiter les données renseignées dans ce formulaire aux fins de traitement et gestion de votre demande d'inscription, de réalisation d'analyses internes et de prospection commerciale. Vous pouvez exercer vos droits, et notamment votre droit d'accès, de rectification et d'opposition, en écrivant à l'adresse email suivante : 
                    <a 
                      class="white--text" 
                      href="mailto:data@gl-events.com"
                    >
                      data@gl-events.com
                    </a>
                    . L'intégralité des informations relatives aux traitements de vos données personnelles figure dans le document Politique de confidentialité dont vous attestez avoir pris connaissance.
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                >
                  <v-switch
                    v-model="form.optout"
                    inset
                    label="Je ne souhaite pas être informé(e) des actualités et recevoir les offres exclusives des partenaires de l'Agence CCC."
                  />
                </v-col>
              </v-row>
            </v-form>

            <p class="text-left">
              <small>* Champs obligatoires</small>
            </p>
          </v-card-text>

          <v-card-text 
            class="px-8"
          >
            <vue-recaptcha
              ref="recaptcha"
              :sitekey="recaptcha.key" 
              :load-recaptcha-script="true" 
              language="fr"
              @verify="onVerify"
              @expired="onExpired"
            />

            <span 
              class="error--text mr-2" 
              v-if="errors['g-recaptcha-response']"
            >
              Erreur au niveau du Captcha!
            </span>

            <v-btn 
              icon 
              small
              @click="resetRecaptcha" 
            >
              <v-icon dark>
                mdi-refresh
              </v-icon>
            </v-btn>
          </v-card-text>

          <v-card-actions class="px-8 pb-8">
            <v-spacer />
            <v-btn 
              @click.prevent="register" 
              color="primary"
              large
              depressed
              :disabled="!recaptcha.valid || !mentions"
            >
              S'inscrire
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'
import api from './api'

export default{
  components: { VueRecaptcha },
  data: () => ({
    mentions: false,
    recaptcha: {
      key: process.env.VUE_APP_RECAPTCHA_KEY,
      valid: false,
    },
    form: {},
    specialites: [],
    emailRules: [
      v => !!v || 'Veuillez renseigner votre email.',
      v => /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v) || 'Adresse email invalide',
    ],
    emailConfirmRules: [
      v => !!v || 'Veuillez confirmer votre email.'
    ],
    passwordRules: [
      v => !!v || 'Veuillez renseigner un mot de passe.',
      v => (v && v.length >= 8) || 'Votre mot de passe doit contenir au minimum 8 caractères.',
      // v => /(?=.*[A-Z])/.test(v) || 'Votre mot de passe doit contenit une majuscule', 
      // v => /(?=.*\d)/.test(v) || 'Votre mot de passe doit contenir un chiffre', 
      // v => /([!@$%])/.test(v) || 'Votre mot de passe doit contenir un caractère spécial [!@#$%]'
    ],
    passwordConfirmRules: [
      v => !!v || 'Veuillez confirmer votre mot de passe.'
    ],
    errors: []
  }),
  methods: {

    // Get list of Roles + Specs
    getParams(){

      api.getParams()
      .then( response => {

        if (response.data){
          this.specialites = response.data.specialites;
        }

      })
      .catch(error => {
    
        if (error.response) {
          // client received an error response (5xx, 4xx)
          if (error.response.status === 401){
            this.$store.dispatch('auth/logoutUser');
            // this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        } else if (error.request) {
          // client never received a response, or request never left
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
        } else {
          // anything else
          this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
        }

      });

    },

    register(){
      if (this.$refs.form.validate()){
        
        this.$store.dispatch('overlay/enable');
        api.register(this.form)
        .then( response => {

          this.$store.dispatch('overlay/disable');
          if (response.data.id){
            this.$store.dispatch('overlay/setMessages',[{type:'success',message:'Votre inscription a été enregistrée avec succès.<br> Veuillez confirmer votre inscription en cliquant sur le lien envoyé par mail à l\'adresse que vous avez spécifiée.'}]);
            this.$refs.form.reset();
          } else {
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
          
        })
        .catch(error => {
          this.$store.dispatch('overlay/disable');
          this.resetRecaptcha();
          if (error.response) {
            if (error.response.status === 422){
              this.$store.dispatch('overlay/setMessages',[{type:'warning',message:error.response.data.messages.join('<br>')}]);
            } else if (error.response.status === 401){
            // client received an error response (5xx, 4xx)
              this.$store.dispatch('auth/logoutUser');
              // this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Votre session a expirée.'}]);
            } else {
              this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
            }
          } else if (error.request) {
            // client never received a response, or request never left
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Erreur de connexion. Merci de réessayer.'}]);
          } else {
            // anything else
            this.$store.dispatch('overlay/setMessages',[{type:'error',message:'Une erreur s\'est produite. Merci de réessayer.'}]);
          }
        });

      } else {
        this.$store.dispatch('overlay/setMessages',[{type:'warning',message:'Merci de vérifier le formulaire.'}]);
      }
    },

    // reCaptcha Methods
    onVerify: function (response) {
      this.recaptcha.valid = true;
      this.form['g-recaptcha-response'] = response;
      this.$store.dispatch['auth/clearErrors'];
    },
    onExpired: function () {
      this.recaptcha.valid = false;
      this.form['g-recaptcha-response'] = null;
      this.$store.dispatch['auth/clearErrors'];
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset()
      this.recaptcha.valid = false;
      this.form['g-recaptcha-response']= null;
    },
    clearFormErrors(){
      this.errors = [];
    },
    
  },
  computed: {
    emailConfirmationRule() {
      return () =>
        this.form.email === this.form.email_confirmation || "Les deux emails doivent correspondre";
    },
    passwordConfirmationRule() {
      return () =>
        this.form.password === this.form.password_confirmation || "Les mots de passe doivent correspondre";
    },
  },
  beforeMount(){
    this.getParams();
    document.title = 'Formuaire d\'inscription';
  },
}
</script>

<style scoped>
.bgcolor{
  background: #e0e0e0;
}
</style>
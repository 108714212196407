<template>
  <v-card 
    class="elevation-0 card-form-topic my-10"
  >
    <div class="d-flex">
      <v-avatar 
        class="mr-6 white--text font-weight-bold text-h5" 
        size="60"
        :color="generateColor(user.initials)"
      >
        {{ user.initials }}
      </v-avatar>
      <div class="flex-grow-1">
        <v-text-field 
          v-model="sujet" 
          label="Sujet de la discussion" 
          outlined 
          clearable
        />
        <VueEditor 
          v-model="message" 
          placeholder="Veuillez entrer votre message..." 
          :editor-toolbar="customToolbar" 
        />
        <v-btn 
          @click="toggleForm" 
          color="grey lighten-2" 
          depressed 
          class="mt-2 mr-2"
        >
          <template>
            Annuler
          </template>
          <v-icon 
            right 
            dark
          >
            mdi-cancel
          </v-icon>
        </v-btn>
        <v-btn 
          @click="saveSubject" 
          :disabled="btnDisabled" 
          color="warning" 
          depressed 
          class="mt-2"
        >
          <template>
            Enregistrer
          </template>
          <v-icon 
            right 
            dark
          >
            mdi-content-save-outline
          </v-icon>
        </v-btn>
      </div>
    </div>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
import colorAvatarMixin from '@/mixins/colorAvatar'

export default {
  props: {
    topic: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    sujet: "",
    message: "",
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  }),
  components: {
    VueEditor,
  },
  methods: {
    saveSubject() {
      this.$emit('saveSubject', { sujet: this.sujet, message: this.message });
    },
    toggleForm() {
      this.$emit('toggleForm');
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/getUser'];
    },
    btnDisabled() {
      if (this.message.replace(/<\/?[^>]+(>|$)/g, "").trim().length > 0 && this.sujet.trim().length > 3) {
        return false;
      }
      return true;
    }
  },
  mixins: [
    colorAvatarMixin,
  ],
}
</script>

